const ScoreDisplay= ({ score, label }) => (
    <div className="px-4 bg-grayColor rounded-lg shadow border-[0.5px] border-grayDisabled flex flex-col   justify-center items-center gap-4">
      <div className="text-lg  whitespace-nowrap">
        {" "}
        {label.split(" ").slice(0, 3).join(" ") +
          (label.split(" ").length > 3 ? "..." : "")}
      </div>
      <div className="text-2xl font-bold  text-forestGreen">{score}</div>
    </div>
  );
  
  export default ScoreDisplay;