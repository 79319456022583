import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip,
} from "chart.js";
import { format, parseISO } from "date-fns";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip
);

const AreaChart = ({ chartData }) => {
  const createGradient = (ctx, area) => {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient.addColorStop(0, "rgba(255, 229, 166, 0.3)");
    gradient.addColorStop(1, "rgba(255, 229, 166, 1)");
    return gradient;
  };
  let label = ["S", "M", "T", "W", "T", "F", "S"];
  let newData = [50, 60, 55, 70, 65, 70, 90];
  if (chartData) {
    const sortedData = chartData.sort((a, b) => {
      return new Date(a.timeGroup) - new Date(b.timeGroup);
    });
    label = sortedData.map((item) => format(parseISO(item.timeGroup), "EEEEE"));
    newData = sortedData.map((item) => item.engineeringCostSaved);
  }
  const data = {
    labels: label,
    datasets: [
      {
        label: "Weekly Progress",
        data: newData,
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return createGradient(ctx, chartArea);
        },
        borderColor: "rgba(241, 194, 77, 1)",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        min: 0,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  return <Line data={data} options={options} />;
};

export default AreaChart;
