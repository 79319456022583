import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import responseInterceptors from "./apis/axios";
import Header from "./components/Header/Header";
import LoginPage from "./components/Login/LoginPage";
import { QueryClientProvider, QueryClient } from "react-query";
import ErrorPage from "./common/ErrorPage";
import AssignCandidate from "./components/AssignCandidate/AssignCandidate";
import RoleList from "./components/RoleList/Rolelist";
import Assessment from "./components/AssessmentList/index";
import AssessmentList from "./components/AssessmentList/AssessmentList";
import CandidateList from "./components/CandidateList/CandidateList";
import Dashboard from "./components/dashboard";
import Home from "./components/Home/home";
import { useState } from "react";
import AssessmentDetails from "./components/AssessmentDetails/AssessmentDetails";
import LogoutPage from "./common/LogoutPage";
import toast, { Toaster } from "react-hot-toast";
import ReportCard from "./components/ReportCard/ReportCard";
import ReportCardSideBar from "./components/ReportCardSideBar/ReportCardSideBar";
import ProtectedRoute from "./components/ProtectedRoute";
import { Toaster as ShadcnToaster } from "./components/ui/toaster";
import CandidatesGeneralPage from "./pages/CandidatesGeneralPage";

responseInterceptors();

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 30 * 1000,
      },
    },
  });
  const navigate = useNavigate();

  // Check authentication status from localStorage on initial load
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem("isAuthenticated") === "true";
  });

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.clear();
    localStorage.clear();
    toast.success("Logout Successfully");
    navigate("/login"); // Navigate to the logout page
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    toast.success("Login Successfully");
    sessionStorage.setItem("isAuthenticated", "true");
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ShadcnToaster />

      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/assessments" />
            ) : (
              <LoginPage onLogin={handleLogin} />
            )
          }
        />
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />

        <Route
          path="/"
          element={
            <ProtectedRoute isLocked={false}>
              <Home onLogout={handleLogout} />
            </ProtectedRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <ProtectedRoute isLocked={false}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="assessments" element={<Assessment />}>
            <Route index element={<AssessmentList />} />
            <Route
              path="exam/:examId/assigncandidate"
              element={<AssignCandidate />}
            />
            <Route
              path="exam/:examId/assessmentdetail"
              element={<AssessmentDetails />}
            />
            <Route path="exam/:examId/candidate" element={<CandidateList />} />
            <Route path="Reportcard" element={<ReportCardSideBar />} />
          </Route>
          <Route path="candidates" element={<CandidateList />} />
          <Route
            path="exam/:examId/user/:userId/report"
            element={<ReportCard />}
          />
          <Route
            path="integrations"
            element={
              <ProtectedRoute isLocked={true}>
                <div>Integrations Page</div>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="candidates-general"
          element={
            <ProtectedRoute isLocked={false}>
              <CandidatesGeneralPage />
            </ProtectedRoute>
          }
        />
        <Route path="logout" element={<LogoutPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </QueryClientProvider>
  );
};

const RootApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default RootApp;
