import axios from 'axios';

const show=async(token)=>{
    const url=`${process.env.REACT_APP_PUBLIC_URL}/organization/getCandidates`;

    try{
          const response=await  axios.get(url,{
            headers:{
                Authorization:`Bearer ${token}`
            }
          })
          return response;
            }
            catch(error){
                console.log(error);
            }
}
const candidateListApi={show};
export default candidateListApi;

