import React, { memo } from "react";
import StringFilter from "./Filters/StringFilter";
import NumberFilter from "./Filters/NumberFilter";
import DateFilter from "./Filters/DateFilter";
import BooleanFilter from "./Filters/BooleanFilter";

const FilterGenerator = ({
  filter,
  handleCheckedChange,
  handleStringFilterChange,
  filterKey,
}) => {
  switch (filter.type) {
    case "string":
      return (
        <StringFilter
          filter={filter}
          filterKey={filterKey}
          handleStringFilterChange={handleStringFilterChange}
          handleCheckedChange={handleCheckedChange}
        />
      );
    case "number":
      return (
        <NumberFilter
          filter={filter}
          filterKey={filterKey}
          handleCheckedChange={handleCheckedChange}
        />
      );
    case "date":
      return (
        <DateFilter
          filter={filter}
          filterKey={filterKey}
          handleCheckedChange={handleCheckedChange}
        />
      );
    case "boolean":
      return (
        <BooleanFilter
          filter={filter}
          filterKey={filterKey}
          handleCheckedChange={handleCheckedChange}
        />
      );
    default:
      break;
  }
};

export default memo(FilterGenerator);
