import axios from 'axios';

const fetch = async ({ token, email, examId }) => {
  if (!email) {
    throw new Error("Email is required");
  }

  const url = `${process.env.REACT_APP_PUBLIC_URL}/exam/${examId}/addUsers`;
  const formattedParams = {
    usersToAdd: email.split(',')
  };

  try {
    const response = await axios.post(url, formattedParams, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
    });
    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    throw new Error(error.response?.data?.message || "Failed to assign candidate");
  }
};

const assignCandidateApi = { fetch };
export default assignCandidateApi;
