import { Outlet } from "react-router-dom";
import Header from "../Header/Header";

const Home = ({ onLogout }) => {
  return (
    <div className="flex min-h-screen">
      <div className="fixed left-0 top-0 h-screen z-30">
        <Header onLogout={onLogout} />
      </div>

      <div className="flex-1 ml-[90px] bg-[#f0f2f5]">
        <div className="min-h-screen flex justify-center">
          <div className="w-full max-w-[1400px] p-8">
            <div className="rounded-xl border bg-white shadow-sm">
              <div className="p-6">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
