import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import FilterWrapper from "./FilterWrapper";

const BooleanFilter = ({
  filter,
  filterKey,
  handleCheckboxChange,
}) => {
  let options = filter.options;

  if (!options || !Array.isArray(options) || options.length === 0) {
    options = [{ value: "is", label: "is" }];
  }

  return (
    <FilterWrapper
      filter={filter}
      filterKey={filterKey}
      handleCheckboxChange={handleCheckboxChange}
    >
      <Select>
        <SelectTrigger className="w-[100px] outline-none focus:ring-0 h-7 bg-[#F0F2F6]">
          <SelectValue placeholder="is" />
        </SelectTrigger>
        <SelectContent>
          {options.map((option, i) => (
            <SelectItem value={option.value} key={i}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select>
        <SelectTrigger className="w-[100px] outline-none focus:ring-0 h-7 ">
          <SelectValue placeholder="True" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={true}>True</SelectItem>
          <SelectItem value={false}>False</SelectItem>
        </SelectContent>
      </Select>
    </FilterWrapper>
  );
};

export default BooleanFilter;
