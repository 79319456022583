import { useState } from "react";
import {
  Users,
  ChevronRight,
  ChevronDown,
  UserPlus,
  MoreVertical,
  Edit2,
  Trash2,
  Lock,
  Settings,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { useToast } from "../ui/use-toast";
import moment from "moment";

const RoleCard = ({ role }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoveredAssessmentId, setHoveredAssessmentId] = useState(null);
  const { toast } = useToast();

  const handleLockedFeature = () => {
    toast({
      title: "Feature Locked",
      description: "Please contact admin to enable this feature.",
      variant: "destructive",
    });
  };

  // Calculate total candidates from assessments
  const totalAssigned = role.assessments?.reduce(
    (sum, assessment) => sum + (parseInt(assessment.totalAssigned) || 0),
    0
  );
  const totalAttended = role.assessments?.reduce(
    (sum, assessment) => sum + (parseInt(assessment.totalAttended) || 0),
    0
  );

  return (
    <div className="space-y-2">
      <div className="group relative rounded-lg border bg-card text-card-foreground shadow-sm transition-all duration-200 hover:shadow-md">
        <div className="p-6">
          <div className="flex items-center justify-between" onClick={() => setIsExpanded(!isExpanded)}>
            {/* Title and Meta Section */}
            <div className="space-y-1.5">
              <div className="flex items-center gap-2">
                <Button
                  variant="ghost"
                  size="sm"
                  className="p-0 hover:bg-transparent"
                  
                >
                  {isExpanded ? (
                    <ChevronDown className="h-5 w-5" />
                  ) : (
                    <ChevronRight className="h-5 w-5" />
                  )}
                </Button>
                <h3 className="font-semibold hover:text-blue-600 cursor-pointer">
                  {role.name}
                </h3>
                <Badge
                  variant="secondary"
                  className="bg-blue-100 text-blue-700"
                >
                  {role.assessments?.length || 0} Assessments
                </Badge>
              </div>

              <div className="flex items-center gap-2 text-sm text-muted-foreground ml-7">
                <span>{moment(role.createdAt).format("Do MMM YYYY")}</span>
                <span>•</span>
                <span>Created by {role.createdBy}</span>
              </div>
            </div>

            {/* Stats and Actions */}
            <div className="flex items-center gap-6">
              {/* Stats */}
              <div className="flex items-center gap-4">
                <div className="flex flex-col items-center px-3 py-1 rounded-md bg-secondary/20">
                  <div className="flex items-center gap-1">
                    <Users className="h-4 w-4" />
                    <span className="text-lg font-semibold">
                      {totalAssigned}
                    </span>
                  </div>
                  <span className="text-xs text-muted-foreground">
                    Assigned
                  </span>
                </div>
                <div className="flex flex-col items-center px-3 py-1 rounded-md bg-secondary/20">
                  <div className="flex items-center gap-1">
                    <Users className="h-4 w-4" />
                    <span className="text-lg font-semibold">
                      {totalAttended}
                    </span>
                  </div>
                  <span className="text-xs text-muted-foreground">
                    Attended
                  </span>
                </div>
              </div>

              {/* Add Role Actions Dropdown */}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm">
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[200px]">
                  <DropdownMenuItem
                    className="text-muted-foreground cursor-not-allowed"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLockedFeature();
                    }}
                  >
                    <Edit2 className="h-4 w-4 mr-2" />
                    Edit Role
                    <Lock className="h-3 w-3 ml-auto" />
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="text-muted-foreground cursor-not-allowed"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLockedFeature();
                    }}
                  >
                    <Settings className="h-4 w-4 mr-2" />
                    Role Settings
                    <Lock className="h-3 w-3 ml-auto" />
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="text-destructive text-muted-foreground cursor-not-allowed"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLockedFeature();
                    }}
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    Delete Role
                    <Lock className="h-3 w-3 ml-auto" />
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>

      {/* Nested Assessments */}
      {isExpanded && (
        <div className="pl-8 space-y-2">
          {role.assessments?.map((assessment) => (
            <div
              key={assessment.examId}
              className="rounded-lg border bg-card/50 p-4 transition-all hover:bg-card/80"
              onMouseEnter={() => setHoveredAssessmentId(assessment.examId)}
              onMouseLeave={() => setHoveredAssessmentId(null)}
            >
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <h4 className="font-medium">{assessment.examName}</h4>
                  <div className="flex items-center gap-2 text-sm text-muted-foreground">
                    <span>
                      {moment(assessment.createdAt).format("Do MMM YYYY")}
                    </span>
                    <span>•</span>
                    <span>Created by {assessment.createdBy}</span>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <Badge variant="outline" className="gap-1">
                      <Users className="h-3 w-3" />
                      {assessment.totalAssigned || 0} Assigned
                    </Badge>
                    <Badge variant="outline" className="gap-1">
                      <Users className="h-3 w-3" />
                      {assessment.totalAttended || 0} Attended
                    </Badge>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex items-center gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() =>
                        navigate(
                          `/assessments/exam/${assessment.examId}/assigncandidate`
                        )
                      }
                      className="gap-2"
                    >
                      <UserPlus className="h-4 w-4" />
                      <span
                        className={`${
                          hoveredAssessmentId === assessment.examId
                            ? "opacity-100"
                            : "opacity-0"
                        } transition-opacity duration-200`}
                      >
                        Assign
                      </span>
                    </Button>

                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        navigate(`exam/${assessment.examId}/candidate`, {
                          state: {
                            title: assessment.examName,
                            icon:true,
                          },
                        });
                      }}
                      className="gap-2"
                    >
                      <Users className="h-4 w-4" />
                      <span
                        className={`${
                          hoveredAssessmentId === assessment.examId
                            ? "opacity-100"
                            : "opacity-0"
                        } transition-opacity duration-200`}
                      >
                        View
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RoleCard;
