import React from "react";
import CandidateListCard from "./CandidateListCard";
import SearchIcon from "../../../assets/icons/SearchIcon";

const CandidatesList = ({ students,page }) => {
  return (
    <div className="bg-white w-full rounded-md overflow-y-auto flex flex-col">
      <header className="flex justify-between items-center sticky top-0 z-10 bg-white p-4">
        <div className="text-sm">
          <p className="text-base font-semibold">
            Candidate List ({students?.length})
          </p>
          <div className="inline-flex items-center gap-4 text-[#585858]">
            <p>
              Showing {(page - 1) * 10 + 1}-
              {Math.min(page * 10, students?.length)} out of {students?.length}{" "}
              results
            </p>
            <button className="text-[#4D80F4]">Show All</button>
          </div>
        </div>
        <div className="max-w-32 px-4 py-1.5 flex items-center justify-between border-[#383838] gap-2 border rounded-full bg-white overflow-hidden">
          <input
            type="text"
            className="bg-transparent w-full outline-none text-[#383838] placeholder:text-[#383838]"
            placeholder="Search"
          />
          <SearchIcon />
        </div>
      </header>
      <main>
        <div className="space-y-4 p-4">
          {students?.slice((page - 1) * 10, page * 10).map((student, index) => (
            <CandidateListCard key={index} student={student} />
          ))}
        </div>
      </main>
    </div>
  );
};

export default CandidatesList;
