import { Navigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ProtectedRoute = ({ children, isLocked }) => {
  const token = sessionStorage.getItem("token");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated") === "true";
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!isAuthenticated || !token) {
      navigate("/login", { replace: true });
    }
  }, [token]);

  if (isLocked) {
    toast.error("This feature is locked. Please contact admin.");
    return <Navigate to="/assessments" replace />;
  }

  if (isAuthenticated && token) {
    return children;
  }
  return null;
};

export default ProtectedRoute;
