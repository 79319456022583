import React from "react";
import FilterWrapper from "./FilterWrapper";

const NumberFilter = ({ filterKey, filter, options, handleCheckboxChange }) => {
  return (
    <FilterWrapper
      filter={filter}
      filterKey={filterKey}
      handleCheckboxChange={handleCheckboxChange}
    >
      
    </FilterWrapper>
  );
};

export default NumberFilter;
