import { NavLink } from "react-router-dom";

const LogoutPage = () => {
  return (
    <section className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">You are logged out</h2>
        <p className="text-lg text-gray-600 mb-8">Thank you for using our application.</p>
        <NavLink
          to="/"
          className="px-6 py-2 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
        >
          Return to Login Page
        </NavLink>
      </div>
    </section>
  );
};

export default LogoutPage;
