import React from "react";



const StatusModal = ({
  isOpen,
  onClose,
  onSubmit,
  status,
}) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm z-10 ">
    <div className="mt-10 flex flex-col gap-5 ">
      <div className="mx-4 flex flex-col gap-5 rounded-xl bg-lightGray px-10 py-8">
        <h2 className="text-black-500 text-2xl font-bold">Notice</h2>
        <hr className="h-1 border-none bg-black" />
      {status &&  <p className="max-w-md">Candidate is { status==="Shortlisted" ? "Shortlisted":"Rejected"} Before.</p>}
        <p className="max-w-md">Do you want to change the status </p>
        <div className={`mt-4 flex  justify-end   gap-4`}>
        <button className="rounded-full border border-red-500 px-4 py-2 text-red-500 hover:text-white hover:bg-red-500" onClick={onClose}>Cancel</button>
        <button className="rounded-full border hover:bg-softRoyalBlue bg-navyBlue px-4 py-2 text-white" onClick={onSubmit}>Change Status</button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default StatusModal;