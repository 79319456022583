import React from "react";
import FilterWrapper from "./FilterWrapper";

const DateFilter = ({
  filter,
  filterKey,
  options,
  handleFilterChange,
  handleCheckedChange,
}) => {
  return (
    <FilterWrapper
      filterKey={filterKey}
      filter={filter}
      handleCheckedChange={handleCheckedChange}
    ></FilterWrapper>
  );
};

export default DateFilter;
