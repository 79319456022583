import React, { memo } from "react";
import style from "../../style/dashboard/assessmentPerformance.module.css";
import { useQuery } from "react-query";
import DashboardApi from "../../apis/dashboard";
import useStore from "../../store/useStore";
import Loading from "../../common/Loading";
import { Navigate } from "react-router-dom";
import { Card, CardContent, CardHeader } from "../ui/card";

const BarChart = ({
  label,
  primaryValue,
  secondaryValue,
  primaryColor = "rgba(241, 194, 77, 1)",
  secondaryColor = "rgba(255, 229, 166, 1)",
}) => (
  <div className={style.barChart}>
    <div>
      <div
        style={{
          backgroundColor: primaryColor,
          height: `${primaryValue * 50 || 10}px`,
          minHeight: "10px",
          maxHeight: "273px",
        }}
      ></div>
      <div
        style={{
          backgroundColor: secondaryColor,
          height: `${secondaryValue * 50 || 10}px`,
          minHeight: "10px",
          maxHeight: "273px",
        }}
      ></div>
    </div>
    <span className="h-10">{label}</span>
  </div>
);

const AssessmentPerformance = ({ startTime, endTime }) => {
  const token = useStore((state) => state.token);

  const { data, isLoading, error } = useQuery({
    queryKey: ["assessmentPerformance", startTime, endTime],
    queryFn: () => DashboardApi.getCompare({ token, startTime, endTime }),
    staleTime: 30 * 1000,
  });

  if (isLoading) return <Loading />;

  if (error) return <Navigate to="/login" />;

  const {
    currentOrgAverageMeanScore,
    currentOrgShortlisted,
    currentOrgaverageTimeInHours,
    restOrgAverageMeanScore,
    restOrgShortlisted,
    restOrgaverageTimeInHours,
  } = data?.data || {};

  return (
    <Card>
      <CardHeader className="font-semibold text-xl py-4">
        Assessment Performance
      </CardHeader>
      <CardContent className="mb-0 h-[365px] flex flex-col justify-end">
        <div className="flex items-end gap-4">
          <BarChart
            label="Average Time Taken"
            primaryValue={currentOrgAverageMeanScore}
            secondaryValue={currentOrgShortlisted}
          />
          <BarChart
            label="Average Kodiva Score"
            primaryValue={currentOrgaverageTimeInHours}
            secondaryValue={restOrgAverageMeanScore}
          />
          <BarChart
            label="Pass Rates"
            primaryValue={restOrgShortlisted}
            secondaryValue={Math.round(restOrgaverageTimeInHours)}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default AssessmentPerformance;
