import axios from "axios";

export default class DashboardApi {
  static getBaseURl() {
    return `${process.env.REACT_APP_PUBLIC_URL}/organization/dashboard`;
  }

  static async getHiringFunnel({ token, startTime, endTime }) {
    const url = `${this.getBaseURl()}/hiringFunnel?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { status: 200, data };
    } catch (error) {
      throw error;
    }
  }
  static async getAssessments({ token }) {
    const url = `${this.getBaseURl()}/assessments`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { status: 200, data: response };
    } catch (error) {
      throw error;
    }
  }
  static async getTotalCandidates({ startTime, endTime, token }) {
    const url = `${this.getBaseURl()}/totalCandidates?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { status: 200, data };
    } catch (error) {
      throw error;
    }
  }
  static async getMeanScore({ token, startTime, endTime }) {
    const url = `${this.getBaseURl()}/meanScore?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { status: 200, data: response };
    } catch (error) {
      throw error;
    }
  }
  static async getSaved({ token, startTime, endTime }) {
    const url = `${this.getBaseURl()}/saved?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { status: 200, data: response };
    } catch (error) {
      throw error;
    }
  }
  static async getAssessmentTime({ token, startTime, endTime }) {
    const url = `${this.getBaseURl()}/assessmentTime?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`;
    try {
      const data = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { status: 200, data };
    } catch (error) {
      throw error;
    }
  }
  static async getCompare({ token, startTime, endTime }) {
    const url = `${this.getBaseURl()}/compare?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { status: 200, data: response };
    } catch (error) {
      throw error;
    }
  }
}
