import ClockIcon from "../../../assets/icons/ClockIcon";
import InfoIcon from "../../../assets/icons/Info";
import LocationIcon from "../../../assets/icons/LocationIcon";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import React from "react";
import { ChevronDown } from "lucide-react";
import BriefCaseIcon from "../../../assets/icons/BriefCaseIcon";
import Tag from "../../Tag";

const CandidateInfo = ({ student }) => {
  return (
    <aside>
      <header className="flex items-center flex-wrap justify-between py-4">
        <p>{student?.name}</p>
        <div className="flex items-center gap-2">
          <button className="text-nowrap border border-[#191C45] rounded-full px-2 py-1 ">
            View Full Profile
          </button>
          <button className="flex items-center gap-2 border border-[#191C45] rounded-full px-2 py-1">
            <span>Actions</span> <ChevronDown size={16} />
          </button>
        </div>
      </header>

      <div className="border-y py-4 border-[#CACACA]">
        <div className="flex items-center gap-4 flex-wrap justify-between text-sm text-[#181818]">
          <div className="flex capitalize gap-4 ">
            <p className="flex gap-1 items-center">
              <BriefCaseIcon /> <span>{student?.role}</span>
            </p>
            <p className="flex gap-1 items-center">
              <ClockIcon /> <span>{student?.yearOfExperience}</span>
            </p>
            <p className="flex gap-1 items-center">
              <LocationIcon /> <span>{student?.location}</span>
            </p>
          </div>
          <div className="flex gap-2 ">
            <p className="text-[#383838]">Role Fit Score</p>
            <span className="text-[#3C59B3] font-semibold">
              {student?.score}%
            </span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Add to library</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>

      <div className="py-4 border-b border-[#CACACA]">
        <h2 className="flex items-center gap-2 text-[#858585]">
          Kodiva Insights:
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <InfoIcon />
              </TooltipTrigger>
              <TooltipContent>
                <p>More</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </h2>
        <p className="text-sm py-4">{student.keyInsights}</p>
      </div>
      <div className="py-4">
        <h2 className="flex items-center gap-2 text-[#858585]">
          Key Strengths
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <InfoIcon />
              </TooltipTrigger>
              <TooltipContent>
                <p>More</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </h2>
        <div className="flex items-center gap-4 flex-wrap mt-4">
          {student?.skillStrength?.map((strength, i) => (
            <Tag key={i} title={strength} />
          ))}
        </div>
      </div>
      <div className="py-4">
        <h2 className="flex items-center gap-2 text-[#858585]">
          Key Gaps
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <InfoIcon />
              </TooltipTrigger>
              <TooltipContent>
                <p>More</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </h2>
        <div className="flex items-center gap-4 flex-wrap mt-4">
          {student?.skillGap?.map((gap, i) => (
            <Tag key={i} title={gap} className={"bg-[#FFEDEE]"} />
          ))}
        </div>
      </div>
    </aside>
  );
};

export default CandidateInfo;
