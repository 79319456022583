import { useState, useCallback, useMemo, useEffect } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import { useToast } from "../ui/use-toast";
import useDebounce from "../../hooks/useDebounce";

// APIs
import assignCandidateApi from "../../apis/AssignCandidateApi";
import assignCandidateExamApi from "../../apis/AssignedCandidateWithExamApi";
import copyLinkApis from "../../apis/copyLink";
import ResendEmail from "../../apis/ResendEmail";
import ResetApis from "../../apis/ResetApi";

// Components
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import NoData from "../../common/NoData";

// UI Components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Badge } from "../../components/ui/badge";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";

// Icons
import {
  ChevronLeft,
  Mail,
  Copy,
  Upload,
  Download,
  RefreshCw,
  Lock,
} from "lucide-react";

// Store
import useStore from "../../store/useStore";

const AssignCandidate = () => {
  const { toast } = useToast();
  const [email, setEmail] = useState("");
  const [examLink, setExamLink] = useState("");
  const token = useStore((state) => state.token);
  const { examId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { title } = location.state || {};
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10; // Number of items per page
  const [searchKey, setSearchKey] = useState("");
  const debouncedSearchKey = useDebounce(searchKey, 300);

  // Fetch assigned candidates
  const { data, isLoading, isError } = useQuery({
    queryKey: ["assignedCandidatewithExam", examId],
    queryFn: async () => {
      const response = await assignCandidateExamApi.show({ token, examId });
      if (!response) throw new Error("No response received");
      return response.data || [];
    },
    refetchOnWindowFocus: true,
  });

  // Assign candidate mutation
  const mutation = useMutation(
    ({ token, email, examId }) =>
      assignCandidateApi.fetch({ token, email, examId }),
    {
      onSuccess: () => {
        toast({
          title: "Candidate Assigned",
          description:
            "The candidate has been successfully assigned to the exam.",
        });
        setEmail("");
        queryClient.invalidateQueries("assignedCandidatewithExam");
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast({
          title: "Assignment Failed",
          description: errorMessage,
          variant: "destructive",
        });
      },
    }
  );

  // Resend email mutation
  const resendMutation = useMutation(
    ({ token, email, examId }) => ResendEmail.show({ token, email, examId }),
    {
      onSuccess: () => {
        toast({
          title: "Email Resent",
          description: "The invitation email has been resent successfully.",
        });
        queryClient.invalidateQueries("assignedCandidatewithExam");
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast({
          title: "Email Failed",
          description: errorMessage,
          variant: "destructive",
        });
      },
    }
  );

  // Reset exam mutation
  const resetMutation = useMutation(
    ({ token, examUserId }) => ResetApis.fetch({ token, examUserId, examId }),
    {
      onSuccess: () => {
        toast({
          title: "Exam Reset",
          description: "The exam has been reset successfully.",
        });
        queryClient.invalidateQueries("assignedCandidatewithExam");
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast({
          title: "Reset Failed",
          description: errorMessage,
          variant: "destructive",
        });
      },
    }
  );

  // Handle copy link
  const handleCopyLink = async () => {
    try {
      const response = await copyLinkApis.show({ token, examId });
      const linkValue = response?.data?.link || "";
      await navigator.clipboard.writeText(linkValue);
      toast({
        title: "Link Copied",
        description: "Exam link has been copied to clipboard.",
      });
    } catch (error) {
      toast({
        title: "Copy Failed",
        description: "Failed to copy exam link. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Handle email input
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Handle send invite
  const handleSendInvite = () => {
    const emails = email.split(",").map((e) => e.trim());
    const invalidEmails = emails.filter((e) => !/^\S+@\S+\.\S+$/.test(e));

    if (invalidEmails.length > 0) {
      toast({
        title: "Invalid Emails",
        description: `The following emails are invalid: ${invalidEmails.join(
          ", "
        )}`,
        variant: "destructive",
      });
      return;
    }

    emails.forEach((email) => {
      mutation.mutate({ token, email, examId });
    });
  };

  // Handle CSV upload
  const handleCsvUpload = () => {
    toast({
      title: "Coming Soon",
      description: "CSV upload feature will be available soon!",
      variant: "default",
    });
  };

  // Get status variant for badge
  const getStatusVariant = (status) => {
    switch (status) {
      case "Mail Sent":
      case "enrolled":
        return "secondary";
      case "started":
        return "warning";
      case "ended":
        return "success";
      default:
        return "default";
    }
  };

  // Inside the component, add this sort function
  const sortedCandidates = useMemo(() => {
    if (!data) return [];
    return [...data].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }, [data]);

  // Effect to fetch and set exam link
  useEffect(() => {
    const fetchExamLink = async () => {
      try {
        const response = await copyLinkApis.show({ token, examId });
        setExamLink(response?.data?.link || "");
      } catch (error) {
        console.error("Failed to fetch exam link:", error);
      }
    };
    fetchExamLink();
  }, [token, examId]);

  // Add this before pagination calculation
  const filteredCandidates = useMemo(() => {
    if (!sortedCandidates) return [];
    if (!debouncedSearchKey.trim()) return sortedCandidates;

    const searchTerm = debouncedSearchKey.toLowerCase().trim();
    setPage(1);
    return sortedCandidates.filter(
      (candidate) =>
        candidate.email?.toLowerCase().includes(searchTerm) ||
        candidate.name?.toLowerCase().includes(searchTerm)
    );
  }, [sortedCandidates, debouncedSearchKey]);

  // Update pagination calculation to use filteredCandidates instead of sortedCandidates
  const totalPages = Math.ceil((filteredCandidates?.length || 0) / PAGE_SIZE);
  const paginatedCandidates = useMemo(() => {
    const start = (page - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    return filteredCandidates?.slice(start, end) || [];
  }, [filteredCandidates, page]);

  // Pagination render helper
  const renderPaginationItems = () => {
    const items = [];
    const maxVisible = 5;

    let start = Math.max(1, page - Math.floor(maxVisible / 2));
    let end = Math.min(start + maxVisible - 1, totalPages);

    if (end - start + 1 < maxVisible) {
      start = Math.max(1, end - maxVisible + 1);
    }

    // First page
    if (start > 1) {
      items.push(
        <PaginationItem key="1">
          <PaginationLink onClick={() => setPage(1)}>1</PaginationLink>
        </PaginationItem>
      );
      if (start > 2) {
        items.push(<PaginationItem key="ellipsis1">...</PaginationItem>);
      }
    }

    // Pages
    for (let i = start; i <= end; i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink isActive={page === i} onClick={() => setPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Last page
    if (end < totalPages) {
      if (end < totalPages - 1) {
        items.push(<PaginationItem key="ellipsis2">...</PaginationItem>);
      }
      items.push(
        <PaginationItem key={totalPages}>
          <PaginationLink onClick={() => setPage(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };

  // Add this function with other handlers
  const handleResetExam = (examUserId) => {
    resetMutation.mutate({ token, examUserId });
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorPage />;

  return (
    <div className="w-full min-h-screen flex flex-col">
      {/* Header Section */}
      <div className="w-full border-b bg-gray-50/40">
        <div className="max-w-[1800px] mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Button variant="ghost" size="sm" onClick={() => navigate(-1)}>
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <div>
                <h1 className="text-2xl font-semibold">Assign Candidates</h1>
                <p className="text-sm text-muted-foreground mt-1">
                  {data?.length || 0} candidates assigned
                </p>
              </div>
              {title && (
                <div className="flex items-center gap-2">
                  <span className="text-muted-foreground">—</span>
                  <span className="text-xl font-medium">{title}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 max-w-[1800px] mx-auto w-full px-6 py-6">
        <Tabs defaultValue="email" className="space-y-6">
          <TabsList>
            <TabsTrigger value="email">Email Invite</TabsTrigger>
            <TabsTrigger value="link">Share Link</TabsTrigger>
            <TabsTrigger value="bulk">Bulk Import</TabsTrigger>
          </TabsList>

          {/* Email Invite Tab */}
          <TabsContent value="email" className="space-y-6">
            <div className="rounded-lg border bg-card p-6">
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-semibold">Invite via Email</h3>
                  <p className="text-sm text-muted-foreground">
                    Send exam invitations directly to candidates' email
                    addresses
                  </p>
                </div>

                <div className="flex gap-2">
                  <div className="flex-1">
                    <Input
                      placeholder="Enter email address"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <p className="text-xs text-muted-foreground mt-1">
                      You can add multiple emails separated by commas
                    </p>
                  </div>
                  <Button
                    onClick={handleSendInvite}
                    disabled={mutation.isLoading || !email}
                    className="shrink-0"
                  >
                    <Mail className="h-4 w-4 mr-2" />
                    Send Invite
                  </Button>
                </div>
              </div>
            </div>
          </TabsContent>

          {/* Share Link Tab */}
          <TabsContent value="link" className="space-y-6">
            <div className="rounded-lg border bg-card p-6">
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-semibold">Share Exam Link</h3>
                  <p className="text-sm text-muted-foreground">
                    Share this link with candidates to give them direct access
                  </p>
                </div>

                <div className="flex gap-2">
                  <Input value={examLink} readOnly />
                  <Button
                    variant="outline"
                    onClick={handleCopyLink}
                    className="shrink-0"
                  >
                    <Copy className="h-4 w-4 mr-2" />
                    Copy Link
                  </Button>
                </div>
              </div>
            </div>
          </TabsContent>

          {/* Bulk Import Tab */}
          <TabsContent value="bulk" className="space-y-6">
            <div className="rounded-lg border bg-card p-6">
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <h3 className="text-lg font-semibold">Bulk Import</h3>
                  <Badge variant="secondary" className="gap-1">
                    <Lock className="h-3 w-3" />
                    Coming Soon
                  </Badge>
                </div>
                <p className="text-sm text-muted-foreground">
                  Import multiple candidates using CSV file - This feature will
                  be available soon
                </p>

                <div className="flex items-center gap-4">
                  <Button variant="outline" disabled>
                    <Upload className="h-4 w-4 mr-2" />
                    Upload CSV
                  </Button>
                  <Button variant="outline" disabled>
                    <Download className="h-4 w-4 mr-2" />
                    Download Template
                  </Button>
                </div>
              </div>
            </div>
          </TabsContent>
        </Tabs>

        {/* Recent Assignments Section */}
        <div className="rounded-md border mt-8">
          <div className="p-4 bg-white border-b">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-semibold">Assigned Candidates</h3>
                <p className="text-sm text-muted-foreground">
                  Showing all candidates, sorted by most recent
                </p>
              </div>
              <div className="relative w-[300px]">
                <Input
                  placeholder="Search with email or name"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
            </div>
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className='text-center whitespace-nowrap'>Email</TableHead>
                <TableHead  className="text-center whitespace-nowrap">Name</TableHead>
                <TableHead className="text-center whitespace-nowrap">Status</TableHead>
                <TableHead className="text-center whitespace-nowrap">Assigned On</TableHead>
                <TableHead className="text-center whitespace-nowrap">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedCandidates.map((elem, index) => (
                <TableRow key={index}>
                  <TableCell className='text-center whitespace-nowrap'>{elem.email}</TableCell>
                  <TableCell className='text-center whitespace-nowrap'>{elem.name || "--"}</TableCell>
                  <TableCell className='text-center whitespace-nowrap'>
                    <Badge variant={getStatusVariant(elem.examStatus)}>
                      {elem.examStatus}
                    </Badge>
                  </TableCell>
                  <TableCell className='text-center whitespace-nowrap'>
                    {moment(elem.createdAt).format("Do MMM YYYY")}
                  </TableCell>
                  <TableCell className='text-center whitespace-nowrap '>
                    {(elem.examStatus === "Mail Sent" ||
                      elem.examStatus === "enrolled") && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() =>
                          resendMutation.mutate({
                            token,
                            email: elem.email,
                            examId,
                          })
                        }
                      >
                        <RefreshCw className="h-4 w-4 " />
                        Resend
                      </Button>
                    )}
                    {(elem.examStatus === "ended" ||
                      elem.examStatus === "started") && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleResetExam(elem.id)}
                      >
                        <RefreshCw className="h-4 w-4 " />
                        Reset
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Update the pagination info to use filteredCandidates */}
          <div className="border-t py-4">
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => setPage((p) => Math.max(1, p - 1))}
                    className={
                      page === 1 ? "pointer-events-none opacity-50" : ""
                    }
                  />
                </PaginationItem>

                {renderPaginationItems()}

                <PaginationItem>
                  <PaginationNext
                    onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
                    className={
                      page === totalPages
                        ? "pointer-events-none opacity-50"
                        : ""
                    }
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>

            <div className="text-center text-sm text-muted-foreground mt-2">
              Showing {(page - 1) * PAGE_SIZE + 1} to{" "}
              {Math.min(page * PAGE_SIZE, filteredCandidates.length)} of{" "}
              {filteredCandidates.length} entries
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignCandidate;
