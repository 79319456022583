import React, { memo } from "react";
import style from "../../style/dashboard/hiringFunnel.module.css";
import DashboardApi from "../../apis/dashboard";
import { useQuery } from "react-query";
import useStore from "../../store/useStore";
import { HIRING_FUNNEL_DATA } from "./config";
import { Navigate } from "react-router-dom";
import { Card, CardContent } from "../ui/card";
import toast from "react-hot-toast";

const HiringFunnel = ({ startTime, endTime }) => {
  const token = useStore((state) => state.token);
  console.log({ token });
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["hiringFunnel", startTime, endTime],
    queryFn: async () => {
      const response = await DashboardApi.getHiringFunnel({
        token,
        startTime,
        endTime,
      });
      return response.data || {};
    },
  });
  if (isLoading) {
    return (
      <Card className="min-h-[380px] flex flex-col items-center justify-center w-full">
        <div className={`${style.card} `}>
          <h3 className="font-semibold">Loading Hiring Funnel...</h3>
        </div>
      </Card>
    );
  }

  if (isError) {
    if (error.response.status === 401) {
      return <Navigate to="/login" />;
    }
    toast.error("Error while loading Hiring Funnel");
  }

  const HIRING_FUNNEL_UPDATED_DATA = {
    title: data?.title || "Hiring funnel",
    chart: HIRING_FUNNEL_DATA.chart.map((item, i) => ({
      ...item,
      value: data?.[item.key] || 0,
    })),
  };
  console.log(HIRING_FUNNEL_UPDATED_DATA);

  return (
    <Card className="flex flex-col items-center justify-center">
      <CardContent>
        {error ? (
          <>
            <div className={`${style.heading} text-xl pt-6`}>
              <h3>{HIRING_FUNNEL_UPDATED_DATA.title}</h3>
            </div>
            <div className="min-h-[305px] flex cold items-center justify-center ">
              <p className="font-semibold">No data found!!</p>
            </div>
          </>
        ) : (
          <div className={""}>
            {" "}
            <div className={`${style.heading} text-xl pt-6`}>
              <h3>{HIRING_FUNNEL_UPDATED_DATA.title}</h3>
            </div>
            <div className={`${style.chart} min-h-64`}>
              {HIRING_FUNNEL_UPDATED_DATA?.chart?.map((item, index) => {
                return (
                  <div key={index} className={style.barChart}>
                    <span>{item.value}</span>

                    <div
                      style={{
                        backgroundColor: item?.color,
                        height: `${item.value * 50}px`,
                        maxHeight: "150px",
                        minHeight: "20px",
                      }}
                    ></div>

                    <span>{item.label}</span>
                  </div>
                );
              })}
            </div>
            <p>
              Your conversion rates are{" "}
              <b>{Math.ceil(data.conversionRate || 0)}%</b> and your exam
              completion rates are <b>{Math.ceil(data.completionRate || 0)}%</b>
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(HiringFunnel);
