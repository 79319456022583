import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const getGroupedDate = (data) => {
  if (!data) {
    return "September 20 27";
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Function to extract Month and Day
  const getMonthDay = (dateStr) => {
    const date = new Date(dateStr);
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return { month, day };
  };

  const sortedDates = data
    .map((item) => item.timeGroup)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const minDate = sortedDates[0];
  const maxDate = sortedDates[sortedDates.length - 1];

  const { month: minMonth, day: minDay } = getMonthDay(minDate);
  const { month: maxMonth, day: maxDay } = getMonthDay(maxDate);

  // Format the Result
  const result =
    minMonth === maxMonth
      ? `${minMonth} ${minDay} - ${maxDay}` // Same month
      : `${minMonth} ${minDay} - ${maxMonth.slice(0, 3)} ${maxDay}`; // Different months (shortened month name)

  return result;
};
export function formatCurrency(value) {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value);
}

export const formatUTCDate = (date) => {
  const utcDate = new Date(date);
  const year = utcDate.getUTCFullYear();
  const month = utcDate.toLocaleString("default", {
    month: "short",
    timeZone: "UTC",
  });
  const day = utcDate.getUTCDate();

  return `${month} ${day}, ${year}`;
};
