import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import useStore from "../../store/useStore";
import BlockMobileAccess from "./MobileAccess";
import CustomStepper from "./Stepper";
import ErrorPage from "../../common/ErrorPage";
import Loading from "../../common/Loading";
import ReportCardApi from "../../apis/ReportCardApi";
import ShortlistedApi from "../../apis/Shortlisted";
import StatusModal from "../ReportCard/StatusModal";

const ReportCardSideBar = () => {
//   const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [isShortlisted, setIsShortlisted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState(""); // Track current status
  const [newStatus, setNewStatus] = useState(""); // Track intended status to change

  const token = useStore((state) => state.token);
  const examId = 2; // Static example values
  const userId = 7;
const navigate=useNavigate();
  // Fetch report card data using React Query
  const fetchReportCardData = async () => {
    const response = await ReportCardApi.show({ token, userId, examId });
    return response;
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["reportCard"],
    queryFn: fetchReportCardData,
    enabled: !!token, // Ensure the query only runs if token exists
  });

  const examUserId=data?.examUserId;

  const getStatus = async () => {
    try {
      const response = await ShortlistedApi.show({examUserId, token});
      if (response.data.data === "Shortlisted") {
        setIsShortlisted(true);
        setIsRejected(false);
        setStatus("Shortlisted");
      } else if (response.data.data === "Rejected") {
        setIsRejected(true);
        setIsShortlisted(false);
        setStatus("Rejected");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStatus();
  }, []);

  const statusChangeFunction = async (newStatus) => {
    try {
      const response = await ShortlistedApi.update({examUserId, newStatus, token});
      if (response && response.status) {
        alert(`User successfully ${newStatus}`);
        localStorage.setItem(`status_${examUserId}`, newStatus);
        setStatus(newStatus);
        setIsShortlisted(newStatus === "Shortlisted");
        setIsRejected(newStatus === "Rejected");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = () => {
    if (newStatus !== status) {
      statusChangeFunction(newStatus);
    } else {
      alert(`User is already ${status.toLowerCase()}.`);
    }
    setIsModalOpen(false);
  };

  const handleStatus = (selectedStatus) => {
    setNewStatus(selectedStatus); // Set the intended new status
    setIsModalOpen(true); // Open the modal for confirmation
  };



  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  // Edge case handling
  if (!token || !examId || !userId) return <div>Invalid request</div>;
  if (isLoading) return <Loading />;
  if (isError) return <ErrorPage />;

  return (
    <>
      {isMobile ? (
        <BlockMobileAccess />
      ) : (
        
  <div className="flex flex-col justify-center  py-2  max-w-7xl " style={{width:'900px'}}>
    <div className="flex flex-col justify-between sm:flex-row items-start p-4 w-full">
      <div class="flex flex-col justify-center gap-4 px-4 py-4 w-full">
        {/* <!-- header --> */}
        <div class="flex items-center justify-between w-full">
          {/* <!-- left --> */}
          <div class="flex flex-col justify-center gap-2 w-full">
            <div>
              <div class="text-xl font-bold">{data.username||'Candidate Name'}</div>
            </div>
            <div class="flex items-center gap-2">
              <img src="" />
              <div>{data.examName||'Role'}</div>
            </div>
          </div>
          {/* <!-- right --> */}
          {data.recommended && data.recommended==="Recommended" &&( <button class="flex items-center gap-2 rounded-md bg-grayColor px-2 py-2">
            <div>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6668 5.5L7.50016 14.6667L3.3335 10.5" stroke="#3E721E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="text-sm font-semibold text-forestGreen">Recommended</div>
          </button>)}
        </div>
        
        {/* <!-- body part 1 --> */}
        <div class="flex items-center justify-between">
          {/* <!-- left part --> */}
          <div class="flex items-center gap-4">
            <div class="flex flex-col justify-center gap-2 rounded-md border border-grayDisabled px-2 py-2">
              <div class="flex items-center gap-2">
                <div>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3335 2.6665H2.66683C1.93045 2.6665 1.3335 3.26346 1.3335 3.99984V11.9998C1.3335 12.7362 1.93045 13.3332 2.66683 13.3332H13.3335C14.0699 13.3332 14.6668 12.7362 14.6668 11.9998V3.99984C14.6668 3.26346 14.0699 2.6665 13.3335 2.6665Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.6668 4.6665L8.68683 8.4665C8.48101 8.59545 8.24304 8.66384 8.00016 8.66384C7.75728 8.66384 7.51931 8.59545 7.3135 8.4665L1.3335 4.6665" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div>{data.email||'email'}</div>
              </div>
              <div class="flex items-center gap-2">
                <div>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_7284_22624)">
                      <path d="M14.6665 11.2802V13.2802C14.6672 13.4659 14.6292 13.6497 14.5548 13.8198C14.4804 13.9899 14.3713 14.1426 14.2345 14.2681C14.0977 14.3937 13.9362 14.4892 13.7603 14.5487C13.5844 14.6082 13.398 14.6303 13.2131 14.6136C11.1617 14.3907 9.19113 13.6897 7.45979 12.5669C5.84901 11.5433 4.48335 10.1777 3.45979 8.56689C2.33311 6.8277 1.63195 4.84756 1.41313 2.78689C1.39647 2.60254 1.41838 2.41673 1.47746 2.24131C1.53654 2.06589 1.63151 1.90469 1.7563 1.76797C1.8811 1.63126 2.033 1.52203 2.20232 1.44724C2.37164 1.37245 2.55469 1.33374 2.73979 1.33356H4.73979C5.06333 1.33038 5.37699 1.44495 5.6223 1.65592C5.86761 1.86689 6.02784 2.15986 6.07313 2.48023C6.15754 3.12027 6.31409 3.74871 6.53979 4.35356C6.62949 4.59218 6.6489 4.8515 6.59573 5.10081C6.54256 5.35012 6.41903 5.57897 6.23979 5.76023L5.39313 6.60689C6.34216 8.27592 7.7241 9.65786 9.39313 10.6069L10.2398 9.76023C10.4211 9.58099 10.6499 9.45746 10.8992 9.40429C11.1485 9.35112 11.4078 9.37053 11.6465 9.46023C12.2513 9.68593 12.8797 9.84248 13.5198 9.92689C13.8436 9.97258 14.1394 10.1357 14.3508 10.3852C14.5622 10.6348 14.6746 10.9533 14.6665 11.2802Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_7284_22624">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>{data?.phoneNo||''}</div>
              </div>
            </div>
            <a href={data.linkedin} target="_blank" rel="noreferrer">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.25" y="0.25" width="43.5" height="43.5" rx="7.75" stroke="#CCCCCC" stroke-width="0.5" />
                <path d="M26 18C27.5913 18 29.1174 18.6321 30.2426 19.7574C31.3679 20.8826 32 22.4087 32 24V31H28V24C28 23.4696 27.7893 22.9609 27.4142 22.5858C27.0391 22.2107 26.5304 22 26 22C25.4696 22 24.9609 22.2107 24.5858 22.5858C24.2107 22.9609 24 23.4696 24 24V31H20V24C20 22.4087 20.6321 20.8826 21.7574 19.7574C22.8826 18.6321 24.4087 18 26 18Z" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 19H12V31H16V19Z" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14 16C15.1046 16 16 15.1046 16 14C16 12.8954 15.1046 12 14 12C12.8954 12 12 12.8954 12 14C12 15.1046 12.8954 16 14 16Z" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </a>
            <a href={data.github} target="_blank" rel="noopener noreferrer">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.25" y="0.25" width="43.5" height="43.5" rx="7.75" stroke="#CCCCCC" stroke-width="0.5" />
                <path d="M24.9998 32V28C25.1389 26.7473 24.7797 25.4901 23.9998 24.5C26.9998 24.5 29.9998 22.5 29.9998 19C30.0798 17.75 29.7298 16.52 28.9998 15.5C29.2798 14.35 29.2798 13.15 28.9998 12C28.9998 12 27.9998 12 25.9998 13.5C23.3598 13 20.6398 13 17.9998 13.5C15.9998 12 14.9998 12 14.9998 12C14.6998 13.15 14.6998 14.35 14.9998 15.5C14.2717 16.5159 13.9183 17.7528 13.9998 19C13.9998 22.5 16.9998 24.5 19.9998 24.5C19.6098 24.99 19.3198 25.55 19.1498 26.15C18.9798 26.75 18.9298 27.38 18.9998 28V32" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19 28C14.49 30 14 26 12 26" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </a>
          </div>
          {/* <!-- right part --> */}
          <div class="rounded-md border border-grayDisabled px-2 py-2">
            <div class="flex flex-col justify-center gap-2">
              <div class="flex items-center gap-2">
                <div class="text-grayStroke">Status:</div>
                <div class="text-sm text-customBlue">{data?.examStatus||'exam status'}</div>
              </div>
              <div class="flex items-center gap-2">
                <div class="text-grayStroke">Date:</div>
                <div class="text-sm text-customBlue">16-nov-2024</div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- body part 2 --> */}
        <div class="flex items-center justify-between">
          {/* <!-- left side  --> */}
          <div class="flex flex-col gap-1 rounded-md bg-grayColor px-4 py-2">
            <div>Kodiva Score</div>
            <div class="text-center text-xl font-semibold">{data?.KodivaScore||''}</div>
          </div>
          <div className="flex items-center gap-6">
            {/* Shortlist Button */}
            <div
              className={`${
                isShortlisted ? "bg-lightGreen" : "bg-grayColor"
              } text-forestGreen px-4 py-2 rounded-lg flex items-center mt-2 sm:mt-0 text-bold cursor-pointer`}
              onClick={() => handleStatus("Shortlisted")}
            >
              <span className="font-bold text-md ">
                {isShortlisted ? "Shortlisted" : "Shortlist"}
              </span>
            </div>
  
            {/* Reject Button */}
            <div
              className={`${
                isRejected ? "bg-redlightColor" : "bg-grayColor"
              } text-redColor px-4 py-2 rounded-lg flex items-center mt-2 sm:mt-0 text-bold cursor-pointer`}
              onClick={() => handleStatus("Rejected")}
            >
              <span className="font-bold text-md ">
                {isRejected ? "Rejected" : "Reject"}
              </span>
            </div>
          </div>
        </div>
        {/* <!-- body part-3 -->
        <!-- timeline --> */}
        <div class="flex flex-col justify-center gap-2 rounded-md border border-grayDisabled px-2 py-2">
          <div>TimeLine</div>
          {/* <!-- scrolle bar type --> */}
          <CustomStepper />
        </div>
        {/* <!-- body part-4 --> */}
        <button class="flex items-center gap-2 self-end rounded-full bg-yellowColor px-4 py-2" onClick={()=>navigate(`/exam/${data.examId}/user/${data.userId}/report`)}>
          <div>View Full Report</div>
          <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.1665 10H15.8332" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 4.1665L15.8333 9.99984L10 15.8332" stroke="#191C45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
      )}
       <StatusModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleStatusChange} // Trigger handleStatusChange on modal submission
            status={status} // Show the intended new status in modal
          />
    </>
  );
};

export default ReportCardSideBar;
