import React from 'react';

const renderText = (text) => {
  // Split the text into lines using '\n'
  const lines = text.split('.\n');

  return lines.map((line, index) => {
    // Process the line to handle bold text by replacing `**` with <strong> tags
    const boldText = line.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
      // Wrap the bold text with <strong> tag for proper HTML rendering
      return `<strong>${p1}</strong>`;
    });

    // Return each line as a list item (<li>) with formatted HTML
    return (
      <li key={index} dangerouslySetInnerHTML={{ __html: boldText }} className='px-2 py-1' />
    );
  });
};







export default  renderText;