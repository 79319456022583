import { useState } from "react";
import { useMutation } from "react-query";
import authApi from "../../apis/auth";
import useStore from "../../store/useStore";
import { useNavigate } from "react-router-dom";
import { useToast } from "../ui/use-toast";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/card";
import { Icons } from "../ui/icons";
import { cn } from "../../lib/utils";

const LoginPage = ({ onLogin }) => {
  const { toast } = useToast();
  const setToken = useStore((state) => state.setToken);
  const navigate = useNavigate();
  const [user, setUser] = useState({
    userIdentifier: "",
    passcode: "",
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleLogin = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    // Reset errors as user types
    if (e.target.name === "userIdentifier") setEmailError("");
    if (e.target.name === "passcode") setPasswordError("");
  };

  const validateInputs = () => {
    let valid = true;

    if (!user.userIdentifier) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(user.userIdentifier)) {
      setEmailError("Enter a valid email address");
      valid = false;
    }

    if (!user.passcode) {
      setPasswordError("Password is required");
      valid = false;
    }

    return valid;
  };

  const loginMutation = useMutation(authApi.fetch, {
    onSuccess: (data) => {
      if (data && data.accessToken) {
        setToken(data.accessToken);
        sessionStorage.setItem("token", data.accessToken);
        onLogin();
        toast({
          title: "Success",
          description: "Login successful",
          variant: "default",
          duration: 3000,
        });
        navigate("/dashboard");
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Invalid credentials",
        variant: "destructive",
        duration: 3000,
      });
      setUser({
        userIdentifier: "",
        passcode: "",
      });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInputs()) {
      try {
        await loginMutation.mutateAsync(user);
      } catch (error) {
        console.error("Login error:", error);
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-slate-50">
      <div className="relative w-full h-full flex flex-col items-center justify-center">
        {/* Background decorative elements */}
        <div className="absolute inset-0 -z-10 bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]">
          <div className="absolute inset-0 bg-slate-50/90 backdrop-blur-3xl" />
        </div>

        <div className="w-full max-w-[380px] space-y-6 p-4">
          {/* Logo & Welcome */}
          <div className="space-y-2 text-center">
            <img
              src="/images/logo.svg"
              alt="Kodiva.ai"
              className="h-10 mx-auto mb-2"
            />
            <CardTitle className="text-2xl font-semibold tracking-tight">
              Welcome back
            </CardTitle>
            <CardDescription className="text-slate-500">
              Sign in to your account to continue
            </CardDescription>
          </div>

          {/* Login Form */}
          <Card className="border-0 shadow-lg">
            <CardContent className="pt-6">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                  <Label
                    htmlFor="email"
                    className="text-sm font-medium text-slate-700"
                  >
                    Email
                  </Label>
                  <div className="relative">
                    <Input
                      id="email"
                      type="email"
                      name="userIdentifier"
                      value={user.userIdentifier}
                      onChange={handleLogin}
                      placeholder="name@example.com"
                      className={cn(
                        "pl-10 h-11 shadow-sm",
                        emailError
                          ? "border-red-500 focus-visible:ring-red-500"
                          : "border-slate-200 focus-visible:ring-slate-200"
                      )}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5 absolute left-3 top-3 text-slate-400"
                    >
                      <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                      <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                    </svg>
                  </div>
                  {emailError && (
                    <p className="text-sm text-red-500">{emailError}</p>
                  )}
                </div>

                <div className="space-y-2">
                  <Label
                    htmlFor="password"
                    className="text-sm font-medium text-slate-700"
                  >
                    Password
                  </Label>
                  <div className="relative">
                    <Input
                      id="password"
                      type="password"
                      name="passcode"
                      value={user.passcode}
                      onChange={handleLogin}
                      placeholder="Enter your password"
                      className={cn(
                        "pl-10 h-11 shadow-sm",
                        passwordError
                          ? "border-red-500 focus-visible:ring-red-500"
                          : "border-slate-200 focus-visible:ring-slate-200"
                      )}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5 absolute left-3 top-3 text-slate-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  {passwordError && (
                    <p className="text-sm text-red-500">{passwordError}</p>
                  )}
                </div>

                <Button
                  className="w-full h-11 bg-[#1e40af] hover:bg-[#1e40af]/90 text-white shadow-sm"
                  type="submit"
                  disabled={loginMutation.isLoading}
                >
                  {loginMutation.isLoading ? (
                    <>
                      <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                      Signing in...
                    </>
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </form>
            </CardContent>
          </Card>

          <p className="text-sm text-center text-slate-500">
            By continuing, you agree to our{" "}
            <a href="#" className="text-[#1e40af] hover:underline">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="#" className="text-[#1e40af] hover:underline">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
