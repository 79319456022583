import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui/accordion";
import { Checkbox } from "../../../ui/checkbox";

const FilterWrapper = ({
  children,
  filter,
  filterKey,
  handleCheckboxChange,
}) => {
 
  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <div className="flex items-center gap-2">
            <Checkbox
              label={filter.label}
              checked={filter.checked}
              onCheckedChange={() => handleCheckboxChange(filterKey)}
            />
            <p>{filter.label}</p>
          </div>
        </AccordionTrigger>
        <AccordionContent className="space-y-2 pl-6">
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default FilterWrapper;
