import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import { format, getDay } from "date-fns";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);

const ChartKey = {
  totalCandidates: "totalCandidates",
  meanScore: "meanScore",
  engineeringHoursSaved: "engineeringHoursSaved",
  averageTimeInHours: "averageTimeInHours",
};

const BarChart = ({ chartData, field }) => {
  let label = ["A", "B", "C", "D", "E", "F", "G"];
  let newData = [10, 20, 50, 70, 40, 20, 60];

  if (!chartData || !field) return null;

  label = chartData.map((item) => format(new Date(item.timeGroup), "EEEE"));
  if (field === ChartKey.totalCandidates) {
    newData = chartData.map((item) => item.totalStudents);
  } else if (field === ChartKey.engineeringHoursSaved) {
    newData = chartData.map((item) => item.engineeringHoursSaved);
  } else if (field === ChartKey.averageTimeInHours) {
    newData = chartData.map((item) => item.averageTime);
  }

  const data = {
    labels: label,
    datasets: [
      {
        label: "Progress",
        data: newData,
        backgroundColor: [
          "rgba(228, 235, 250, 1)",
          "rgba(228, 235, 250, 1)",
          "rgba(228, 235, 250, 1)",
          "rgba(228, 235, 250, 1)",
          "rgba(228, 235, 250, 1)",
          "rgba(228, 235, 250, 1)",
          "rgba(77, 128, 244, 1)",
        ],
        borderRadius: 3,
        barPercentage: 0.8,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
