import React from "react";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
} from "../ui/pagination";

const PaginationComponent = ({ page, setPage, totalPages }) => {
  const getPages = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      pages.push(1);
      if (page > 3) pages.push("...");
      for (
        let i = Math.max(2, page - 1);
        i <= Math.min(totalPages - 1, page + 1);
        i++
      ) {
        pages.push(i);
      }
      if (page < totalPages - 2) pages.push("...");
      pages.push(totalPages);
    }
    return pages;
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber === "...") return;
    setPage(pageNumber);
  };
  return (
    <Pagination>
      <PaginationContent className="flex-wrap mx-4">
        <PaginationItem>
          <PaginationPrevious
            href="#"
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          />
        </PaginationItem>
        {getPages().map((pageNumber, index) => (
          <PaginationItem key={index}>
            {pageNumber === "..." ? (
              <PaginationEllipsis>...</PaginationEllipsis>
            ) : (
              <PaginationLink
                className={`cursor-pointer ${
                  pageNumber === page ? "font-bold" : ""
                }`}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </PaginationLink>
            )}
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationNext
            href="#"
            onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default PaginationComponent;
