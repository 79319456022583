import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import React, { useCallback, useMemo, useState } from "react";
import { FILTERS_LIST } from "../../../constants/Filter";
import { Button } from "../../ui/button";
import FilterGenerator from "./FilterGenerator";

const CandidatesListFilter = () => {
  const [filters, setFilters] = useState(FILTERS_LIST);

  const handleStringFilterChange = useCallback((filter, value, valueType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: {
        ...prevFilters[filter],
        value: value ?? prevFilters[filter].value,
        valueType: valueType ?? prevFilters[filter].valueType,
      },
    }));
  }, []);

  const handleCheckedChange = useCallback((key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: {
        ...prevFilters[key],
        checked: !prevFilters[key].checked,
      },
    }));
  }, []);

  const handleFilterClear = useCallback(() => {
    setFilters(FILTERS_LIST);
  }, []);

  const handleFilterApply = useCallback(() => {
    const appliedFilters = Object.entries(filters).reduce(
      (acc, [key, filter]) => {
        if (filter.checked) {
          acc[key] = { value: filter.value, valueType: filter.valueType };
        }
        return acc;
      },
      {}
    );
    console.log(appliedFilters);
  }, [filters]);

  return (
    <aside className=" w-full max-w-[250px] lg:max-w-[280px] bg-white rounded-md flex flex-col overflow-y-auto">
      <header className="sticky top-0 z-10 bg-white py-4 px-4">
        <p className="font-semibold text-base">Filter Candidates By</p>
      </header>
      <main className="space-y-2 border-t mx-4">
        {Object.entries(filters)?.map(([key, filter]) => (
          <FilterGenerator
            key={key}
            filterKey={key}
            filter={filter}
            handleCheckedChange={handleCheckedChange}
            handleStringFilterChange={handleStringFilterChange}
          />
        ))}
      </main>
      <footer className="flex justify-end gap-4 items-center mt-auto mx-4 py-4 bg-white sticky bottom-0 z-10">
        <button className="text-red-500" onClick={handleFilterClear}>
          Clear All
        </button>
        <Button
          className="rounded-full bg-[#191C45]"
          onClick={handleFilterApply}
        >
          Apply Filters
        </Button>
      </footer>
    </aside>
  );
};

export default CandidatesListFilter;
