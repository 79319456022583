import React from "react";

const ClockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8350_16037)">
        <path
          d="M8.0026 3.9987V7.9987M14.6693 7.9987C14.6693 11.6806 11.6845 14.6654 8.0026 14.6654C4.32071 14.6654 1.33594 11.6806 1.33594 7.9987C1.33594 4.3168 4.32071 1.33203 8.0026 1.33203C11.6845 1.33203 14.6693 4.3168 14.6693 7.9987Z"
          stroke="#858585"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8350_16037">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00195312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockIcon;
