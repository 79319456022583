const StatusIcon=({ status }) => {
    const iconClasses = {
      success: "text-forestGreen",
      warning: "text-orangeColor",
      danger: "text-redColor",
    };
  
    return (
      <span className={`${iconClasses[status]} text-xl font-bold`}>
        {status === "success" && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.1673 1L4.75065 7.41667L1.83398 4.5"
              stroke="#3E721E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        {status === "warning" && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6759 10.9998L8.00925 2.83314C7.90749 2.65359 7.75993 2.50425 7.58162 2.40035C7.40331 2.29644 7.20062 2.2417 6.99425 2.2417C6.78787 2.2417 6.58519 2.29644 6.40688 2.40035C6.22856 2.50425 6.081 2.65359 5.97925 2.83314L1.31258 10.9998C1.20973 11.1779 1.1558 11.3801 1.15625 11.5858C1.15671 11.7915 1.21153 11.9934 1.31517 12.171C1.41881 12.3487 1.56758 12.4958 1.7464 12.5975C1.92522 12.6991 2.12774 12.7517 2.33341 12.7498H11.6667C11.8714 12.7496 12.0725 12.6955 12.2497 12.5931C12.4269 12.4906 12.574 12.3433 12.6762 12.166C12.7785 11.9886 12.8323 11.7875 12.8322 11.5828C12.8322 11.3781 12.7783 11.1771 12.6759 10.9998Z"
              stroke="#C07606"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 5.75V8.08333"
              stroke="#C07606"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 10.4165H7.00778"
              stroke="#C07606"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        {status === "danger" && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.166 3.5L4.16602 10.5"
              stroke="#E04C4C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.16602 3.5L11.166 10.5"
              stroke="#E04C4C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </span>
    );
  };
  
  export default StatusIcon;