import axios from 'axios';

const fetch=async(params)=>{
    const url=`${process.env.REACT_APP_PUBLIC_URL}/user/login`
    try {
        const response=await axios.post(url,params);
        return response.data;
    } catch (error) {
        const errorData = error.response?.data?.data || { error: 'Unknown error' };
        return { success: false, data: errorData };
    }
    
}
const authApi={fetch};
export default authApi;