import axios from 'axios';

const update=async({token,examUserId,newStatus})=>{
    const url=`${process.env.REACT_APP_PUBLIC_URL}/exam/${examUserId}/shortlistStatus`;
    try{
    const response=await axios.put(url,{status:newStatus},{
        headers:{
            Authorization:`Bearer ${token}`,
        }
    })
    return response;
    }
    catch(error){
        console.log(error);
    }

};


const show=async({token,examUserId})=>{
    const url =`${process.env.REACT_APP_PUBLIC_URL}/exam/${examUserId}/getShortlistStatus`
    try {
        const response=await axios.get(url,{
            headers:{
                Authorization:`Bearer ${token}`,
            }
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }

}

const ShortlistedApi={update,show};
export default ShortlistedApi;