
import StatusIcon from "./StatusIcon";
import RenderText from "./RenderText";
const CodeReviewDashboard = ({
    codeReview,
  }) => {
    const getStatus = (score) => {
      if (score >= 80) return "success";
      if (score >= 60) return "warning";
      return "danger";
    };
    const iconClasses = {
      success: "text-forestGreen",
      warning: "text-orangeColor",
      danger: "text-redColor",
    };
  
    return (
      <>
        <div className="flex flex-col mt-4 gap-4">
          {codeReview.subScores.map((subScore, index) => (
            <div
              key={index}
              className="p-3 sm:p-6 rounded-lg bg-white mt-6 shadow border-[0.5px] border-grayDisabled"
            >
              <div className="flex flex-col sm:flex-row justify-between mb-4 items-start sm:items-center">
                <div className="flex gap-4 items-center">
                  <div className="text-sm rounded-full bg-grayColor py-2 px-4 gap-2.5 mb-2 sm:mb-0">
                    {subScore.subtitle === "CODING_PROJECT"
                      ? "Code Writing"
                      : "Code Review"}
                  </div>
                  <h1 className="text-xl sm:ml-2">
                    {subScore.title.split(" ").slice(0, 3).join(" ") +
                      (subScore.subtitle.split(" ").length > 3 ? "..." : "")}
                  </h1>
                </div>
                {/* yeh tite aayega exam ka  */}
                {/* here score */}
                <div className="flex justify-center items-center rounded-xl text-goldenBrown text-xl text-center  font-bold border border-grayDisabled " style={{width:'50px', height:'50px'}}>
                  {subScore.score}
                </div>
              </div>
  
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-3">
                {subScore.scores.map((score, idx) => (
                  <div
                    key={idx}
                    className="border border-grayDisabled px-4 py-4 rounded-md"
                  >
                    <div className="flex justify-between items-center">
                      <div className=" text-md mb-2  flex break-words  w-[80%] lg:w-[88%]">
                        {score.label}
                      </div>
                      {/* <span
                        className={`flex justify-center items-center w-[40px] h-[40px] rounded-xl ${
                          iconClasses[getStatus(score.score)]
                        } text-md font-bold border border-grayDisabled `}
                      >
                        {score.score}
                      </span> */}
                    </div>
  
                    <div>
                      {score.subCategory.map((element, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center "
                        >
                          <div className="flex  gap-2 items-center w-full">
                            <StatusIcon status={getStatus(element.score)} />
                            <div className=" text-md ml-2  flex break-words w-[80%] lg:w-[88%]">
                              {element.label}
                            </div>
                          </div>
                          <div
                            className={`flex justify-center items-center w-[40px] h-[40px] rounded-xl ${
                              iconClasses[getStatus(element.score)]
                            } text-md font-bold  `}
                          >
                            {element.score}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
  
              <div className="flex justify-between mt-6 items-center">
                <h2 className="text-xl  font-bold mb-2">Pull Request</h2>
                <div className="bg-customBlue px-4 py-2 rounded-full text-white ">
                  <a
                    href={codeReview.pullRequest || "#"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex gap-2 items-center text-sm lg:text-md">
                      See Student Submission
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 2H14V6"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.66602 9.33333L13.9993 2"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 mt-8 w-full">
                <div className="space-y-4">
                  <div className="rounded-lg px-4 py-4 shadow border-[0.5px] border-grayDisabled w-full">
                    <h2 className="py-2 text-xl">What we liked</h2>
                    <ul className="list-disc pl-4 ml-4 space-y-2 text-md">
                      {RenderText(codeReview.feedback.liked)}
                    </ul>
                  </div>
  
                  <div className="rounded-lg px-4 py-4 shadow border-[0.5px] border-grayDisabled w-full">
                    <h2 className="py-2 text-xl">What we disliked</h2>
                    {/* <p className="font-semibold">{codeReview.feedback.disliked.title}</p> */}
                    <ul className="list-disc pl-4 ml-4 space-y-2 text-md">
                      {RenderText(codeReview.feedback.disliked)}
                    </ul>
                  </div>
  
                  <div className="rounded-lg px-4 py-4 shadow border-[0.5px] border-grayDisabled w-full">
                    <h2 className=" py-2 text-xl">What we expect</h2>
                    <p className="ml-4 text-md">
                      {RenderText(codeReview.feedback.expected)}
                    </p>
                  </div>
  
                  <div className="rounded-lg px-4 py-4 shadow border-[0.5px] border-grayDisabled w-full">
                    <h2 className=" py-2 text-xl">What was lacking</h2>
                    <p className="ml-4 text-md">
                      {RenderText(codeReview.feedback.lacking)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  
  export default CodeReviewDashboard;

  