import React from "react";

const BriefCaseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8350_16278)">
        <path
          d="M8.0026 7.9987H8.00927M10.6693 3.9987V2.66536C10.6693 2.31174 10.5288 1.9726 10.2787 1.72256C10.0287 1.47251 9.68956 1.33203 9.33594 1.33203H6.66927C6.31565 1.33203 5.97651 1.47251 5.72646 1.72256C5.47641 1.9726 5.33594 2.31174 5.33594 2.66536V3.9987M14.6693 8.66536C12.6911 9.97135 10.373 10.6676 8.0026 10.6676C5.63224 10.6676 3.31408 9.97135 1.33594 8.66536M2.66927 3.9987H13.3359C14.0723 3.9987 14.6693 4.59565 14.6693 5.33203V11.9987C14.6693 12.7351 14.0723 13.332 13.3359 13.332H2.66927C1.93289 13.332 1.33594 12.7351 1.33594 11.9987V5.33203C1.33594 4.59565 1.93289 3.9987 2.66927 3.9987Z"
          stroke="#858585"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8350_16278">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.00195312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BriefCaseIcon;
