import React, { useMemo, useRef, useState } from "react";
import { Input } from "../../../ui/input";
import { Checkbox } from "../../../ui/checkbox";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui/accordion";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
import { Button } from "../../../ui/button";
import { ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../ui/command";
import { Check } from "lucide-react";
import { cn } from "../../../../lib/utils";
import { STRING_FILTER_OPTIONS } from "../../../../constants/Filter";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import FilterWrapper from "./FilterWrapper";

const StringFilter = ({
  filter,
  filterKey,
  handleStringFilterChange,
  handleCheckedChange,
  groups,
}) => {
  const handleCheckboxChange = () => {
    handleCheckedChange(filterKey);
  };

  let options = filter.options;

  if (!options || !Array.isArray(options) || options.length === 0) {
    options = STRING_FILTER_OPTIONS;
  }

  return (
    <FilterWrapper
      filter={filter}
      filterKey={filterKey}
      handleCheckboxChange={handleCheckboxChange}
    >
      <Select
        onValueChange={(val) => handleStringFilterChange(filterKey, null, val)}
        value={filter.valueType}
      >
        <SelectTrigger className="w-[100px] outline-none focus:ring-0 h-7 bg-[#F0F2F6]">
          <SelectValue defaultValue={filter.valueType} placeholder="is" />
        </SelectTrigger>
        <SelectContent>
          {options.map((option, i) => (
            <SelectItem value={option.value} key={i}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {filter.groups &&
      Array.isArray(filter.groups) &&
      filter.groups.length > 0 ? (
        ""
      ) : (
        <Input
          onChange={(e) =>
            handleStringFilterChange(filterKey, e.target.value, null)
          }
          value={filter.value}
          placeholder="Type here"
          className="w-[200px] h-7 mt-2 p-2 outline-none border-[#B5B5B5] shadow-none focus-visible:ring-0 focus:ring-0 ring-0"
        />
      )}
    </FilterWrapper>
  );
};

export default StringFilter;
