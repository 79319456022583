import axios from 'axios';

const show=async (token,examStatus) =>{
    const url=`${process.env.REACT_APP_PUBLIC_URL}/organization/assessments`;
  try{
  const response=await axios.get(url,{
    headers:{
        Authorization: `Bearer ${token}`
    },
    params: examStatus?{examStatus}:{},
  })
  return response;
  }
  catch(error){
    console.log(error);
  }
};

const assessmentList={show};
export default assessmentList;


