export const FilterExperienceYears = [
  "Fresher",
  "1-2",
  "3-5",
  "5-7",
  "7-9",
  "10+ Years",
];
export const FilterInstituteTier = ["Tier 1", "Tier 2", "tier 3"];

export const FilterCompanyTier = [
  "Early Stage Startup",
  "Tier 1",
  "Tier 2",
  "Tier 3",
];
export const STRING_FILTER_OPTIONS = [
  { value: "is", label: "is" },
  {
    value: "isn't",
    label: "isn't",
  },
  {
    value: "contains",
    label: "contains",
  },
  {
    value: "doesn't contain",
    label: "doesn't contain",
  },
  {
    value: "starts with",
    label: "starts with",
  },
  {
    value: "ends with",
    label: "ends with",
  },
  {
    value: "is empty",
    label: "is empty",
  },
  {
    value: "is not empty",
    label: "is not empty",
  },
];

export const FILTERS_LIST = {
  ExperienceYears: {
    options: [],
    valueType: "is",
    value: "",
    label: "Experience Years",
    type: "string",
    groups: [],
    checked: false,
  },
  HighestQualification: {
    valueType: "is",
    value: "",
    label: "Highest Qualification",
    options: [],
    type: "string",
    checked: false,
  },
  City: {
    valueType: "is",
    value: "",
    label: "City",
    options: [],
    type: "string",
    checked: false,
  },
  Email: {
    valueType: "is",
    value: "",
    label: "Email",
    options: [],
    type: "string",
    checked: false,
  },
  phone: {
    valueType: "is",
    value: "",
    label: "Phone",
    options: [],
    type: "string",
    checked: false,
  },
  Tags: {
    valueType: "is",
    value: "",
    label: "Tags",
    options: [],
    type: "boolean",
    checked: false,
  },
};
