import axios from "axios";

const show=async({examId,token,email})=>{
    if (!email) {
        throw new Error("Email is required");
      }
 const url=`${process.env.REACT_APP_PUBLIC_URL}/exam/${examId}/sendExamMails`
 const formattedParams = {
    userIds: email.split(',')
  };

    try {
        const response=await axios.post(url,formattedParams,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
const ResendEmail={show};
export default ResendEmail;
