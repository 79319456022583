import React, { useState } from "react";
import CandidatesListFilter from "./CandidatesListFilter";
import CandidatesList from "./CandidatesList";
import Header from "../Header/Header";
import CandidatesApi from "../../apis/candidates";
import { Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import PaginationComponent from "../Pagination";

const CandidatesGeneral = () => {
  const students = [
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
    {
      name: "John Doe",
      currentRole: "Web developer",
      yearOfExperience: "2 years",
      location: "Haryana",
      score: "88",
      resumeLink: "",
      keyInsights:
        "Handling Payroll Schedules: Although payroll schedules were addressed,the approach lacked precision. Specifically, the handling ofbi-monthly payroll was incomplete, as the solution did not consistently account for both the 15th and the last day of the month.",
      skillStrength: ["react.js", "node.js", "mongodb", "golang"],
      skillGap: ["html", "css", "javascript"],
    },
  ];
  const [page, setPage] = useState(1);
  const token = sessionStorage.getItem("token");
  // const { data, isLoading, isError, error } = useQuery({
  //   queryKey: ["candidates", page],
  //   queryFn: () => CandidatesApi.getCandidates({ token, examId: 1, page }),
  //   // placeholderData: previousDate
  // });
  // if (isError) {
  //   if (error.response.status === 401) {
  //     return <Navigate to="/login" />;
  //   }
  //   return <Navigate to="/" />;
  // }
  // if (isLoading) return "Loading...";
  const totalPages = Math.ceil(students?.length / 10);

  return (
    <div className="flex justify-between h-screen ">
      <header className="z-30">
        <Header />
      </header>
      <div className="flex justify-between p-6 w-full flex-1 gap-4 bg-[#f0f2f5] ">
        <CandidatesListFilter />
        <div className="flex flex-col flex-1">
          <CandidatesList students={students} page={page} />
          <div className="justify-self-end mt-auto py-2 sticky bottom-0 z-10 bg-white">
            <PaginationComponent
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidatesGeneral;
