import StatusIcon from "./StatusIcon";

const ScoreSection= ({ title, items }) => {
    const iconClasses = {
      success: "text-forestGreen",
      warning: "text-orangeColor",
      danger: "text-redColor",
    };
    return (
      <div className="px-4 py-4 bg-white rounded-lg shadow border-[0.5px] border-grayDisabled ">
        <div className="flex flex-row items-center justify-between">
          <h4 className="mb-2 text-md  flex break-words w-[80%] lg:w-[88%]">
            {title}
          </h4>
        </div>
        {items.map((item, index) => (
          <div key={index} className="flex  justify-between items-center mb-2">
            <div className="flex gap-2 items-center w-full">
              <StatusIcon status={item.status} />
              <span className=" text-md ml-2  flex break-words w-[80%] lg:w-[88%]">
                {item.label}
              </span>
            </div>
            <span
              className={` text-md font-semibold ${iconClasses[item.status]}`}
            >
              {item.score}
            </span>
          </div>
        ))}
      </div>
    );
  };

  export default ScoreSection;