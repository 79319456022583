import { cn } from "../../lib/utils";
import { useLocation, Link } from "react-router-dom";
import {
  LayoutDashboard,
  ClipboardList,
  Users,
  LogOut,
  GraduationCap,
  Network,
  Lock,
} from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { toast } from "react-hot-toast";

const Header = ({ onLogout }) => {
  const { pathname } = useLocation();

  const routes = [
    {
      label: "Dashboard",
      icon: LayoutDashboard,
      href: "/dashboard",
      locked: false,
    },
    {
      label: "Assessments",
      icon: ClipboardList,
      href: "/assessments",
      locked: false,
    },
    {
      label: "Candidates",
      icon: Users,
      href: "/candidates",
      locked: false,
    },
    {
      label: "Integrations",
      icon: Network,
      href: "/integrations",
      locked: true,
    },
  ];

  return (
    <div className="flex flex-col h-full bg-[#0F172A] text-white w-[90px]">
      <div className="flex-1">
        {/* Logo Section */}
        <div className="flex flex-col items-center pt-6 pb-8">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            className="mb-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.5356 19.9993C34.5356 28.0256 28.0286 34.5323 20.0017 34.5323C11.9748 34.5323 5.46777 28.0256 5.46777 19.9993C5.46777 11.973 11.9748 5.46631 20.0017 5.46631C28.0286 5.46631 34.5356 11.973 34.5356 19.9993Z"
              fill="#191C45"
              stroke="white"
            />
            <path
              d="M9.82739 29.3044L9.66662 29.4449C7.96289 30.9333 7.81538 33.5867 9.69446 34.8465C11.9852 36.3823 14.4065 37.2355 16.6462 37.6561C19.764 38.2417 20.8995 34.7437 18.5846 32.5747L15.194 29.3979C13.6946 27.993 11.3747 27.9526 9.82739 29.3044Z"
              fill="#4D80F4"
            />
            <path
              d="M17.4297 7.3456L10.4989 13.18C7.63144 15.5938 2.83076 12.909 4.98189 9.8395C5.57427 8.99422 6.28646 8.13585 7.14284 7.27953C9.91487 4.5077 12.955 3.09413 15.7086 2.45385C18.8502 1.72336 19.8972 5.26845 17.4297 7.3456Z"
              fill="#4D80F4"
            />
            <path
              d="M30.7402 11.1571L22.5521 18.2466C20.628 19.9126 20.7286 22.9281 22.7593 24.4622L30.1347 30.034C31.8002 31.2922 34.2013 31.0786 35.3061 29.3076C39.4246 22.7054 38.1416 16.1754 35.9741 11.8809C34.9753 9.90217 32.4159 9.70618 30.7402 11.1571Z"
              fill="#F1C24D"
            />
          </svg>
          <span className="text-sm font-semibold tracking-wider text-white/90">
            Kodiva.ai
          </span>
        </div>

        {/* Navigation */}
        <nav className="px-2">
          {routes.map((route) => (
            <TooltipProvider key={route.href}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="relative">
                    <Link
                      to={route.locked ? "#" : route.href}
                      className={cn(
                        "flex flex-col items-center justify-center h-[70px] mb-1 rounded-lg transition-all duration-200",
                        "hover:bg-white/5",
                        route.locked ? "cursor-not-allowed opacity-50" : "",
                        pathname === route.href
                          ? "bg-[#1E293B] text-[#4D80F4] ring-1 ring-[#4D80F4]/20"
                          : "text-white/60 hover:text-white/90"
                      )}
                      onClick={(e) => {
                        if (route.locked) {
                          e.preventDefault();
                          toast.error(
                            "Please contact admin to enable this feature"
                          );
                        }
                      }}
                    >
                      <route.icon
                        className={cn(
                          "w-5 h-5 transition-colors",
                          pathname === route.href
                            ? "text-[#4D80F4]"
                            : "text-white/60 group-hover:text-white/90"
                        )}
                      />
                      <span
                        className={cn(
                          "text-xs mt-1.5",
                          pathname === route.href
                            ? "font-medium"
                            : "font-normal"
                        )}
                      >
                        {route.label}
                      </span>
                      {route.locked && (
                        <Lock className="absolute top-2 right-2 h-3 w-3 text-white/60" />
                      )}
                    </Link>
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  side="right"
                  className="ml-1 bg-[#1E293B] text-white border-[#334155]"
                >
                  {route.locked
                    ? "Contact admin to enable this feature"
                    : route.label}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </nav>
      </div>

      {/* Logout Button */}
      <div className="p-4 border-t border-white/[0.08]">
        <Button
          variant="ghost"
          onClick={onLogout}
          className={cn(
            "w-full h-[70px] flex flex-col items-center justify-center",
            "text-white/60 hover:text-white/90 hover:bg-white/5",
            "transition-all duration-200"
          )}
        >
          <LogOut className="w-5 h-5" />
          <span className="text-xs mt-1.5">Logout</span>
        </Button>
      </div>
    </div>
  );
};

export default Header;
