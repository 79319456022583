const SkillBar = ({ value, title, markers }) => {
    let preMarker = 0;
    const marks = [...markers, 100];
  
    const getColorClass = (index) => {
      if (index === 1) return "bg-orangeColor";
      if (index === 2) return "bg-forestGreen";
      return "bg-redColor";
    };
  
    const dotPosition = `${value}%`;
  
    return (
      <div className="px-4 py-4 h-auto bg-white rounded-lg shadow border-[0.5px] border-grayDisabled ">
        <h2 className="text-[17px] mb-2">{title}</h2>
        <div className="relative h-1 rounded-full ">
          {marks.map((marker, index) => {
            const width =
              index === marks.length - 1 ? 100 - preMarker : marker - preMarker;
            const divElement = (
              <div
                key={index}
                className={`absolute h-full ${getColorClass(index)}`}
                style={{ width: `${width}%`, left: `${preMarker}%` }}
              ></div>
            );
            preMarker = marker;
            return divElement;
          })}
          <div
            className="absolute flex justify-center items-center"
            style={{ left: dotPosition, top: -1.5 }}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cy="6" cx="6" r="6" fill="#191C45" />
            </svg>
          </div>
        </div>
        <div className="relative h-6 rounded-full mt-2">
          {markers.map((marker, index) => (
            <div
              key={index}
              className="absolute text-md -ml-2"
              style={{ left: `${marker}%` }}
            >
              {marker !== value && <span>{marker}</span>}
            </div>
          ))}
          <div
            className="absolute rounded-full  text-md py-0.5 px-1 -ml-3 text-black font-bold"
            style={{ left: dotPosition }}
          >
            <span>{value}</span>
          </div>
        </div>
      </div>
    );
  };
  
  export default SkillBar;