import { useState } from "react";
import Header from "../Header/Header";
import useStore from "../../store/useStore";
import { useParams } from "react-router-dom";

const AssessmentDetails = () => {
  const [sections, setSectionIsopen] = useState({
    jobRole: false,
    capabilityMap: false,
    assessmentCriteria: false,
    assignStudent: false,
  });
  const token=useStore((state)=>state.token);
  const examId=useParams();
  const toggleDown = (section) => {
    setSectionIsopen((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  return (
    // <div className="flex  gap-80">
    //   <Header/>
    <div className="mt-4 flex flex-col items-center gap-10">
      <div className="text-xl font-bold">Full Stack SDE1 Assessment</div>
      {/* <!-- body part  --> */}
      <div className="flex  flex-col justify-center gap-4" style={{width:'800px'}}>
        <div>
          {/* <!-- job role and description --> */}
          <div className="flex items-center justify-between bg-whisperGray px-4 py-2 rounded-md">
            <div className="text-sm">Job Role and Description</div>
            <div onClick={() => toggleDown("jobRole")}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.00195 7.5L10.002 12.5L15.002 7.5"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>


        <div>
          {/* <!-- assesment Questions --> */}
          <div className="flex items-center justify-between bg-whisperGray px-4 py-2 rounded-md">
            <div className="text-sm">Assessment Questions</div>
            <div onClick={() => toggleDown("assessmentCriteria")}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.00195 7.5L10.002 12.5L15.002 7.5"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          
          {sections.assessmentCriteria && (
          <div className="mt-6 flex gap-4 px-4 py-2 rounded-md">
            {/* <!-- left --> */}
            <div className="flex flex-1 flex-col gap-4">
              <div className="text-md font-semibold">
                Code Review for Real-Time Conversation Platform
              </div>
              <div className="text-sm flex">
                <div>
                  Description: in the assessment Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Ut officiis aliquid temporibus
                  quidem sunt modi nihil? Eum nisi ullam voluptatibus enim nemo
                  suscipit quam at tenetur est provident, earum quod!
                </div>
              </div>
              <div className="flex flex-wrap gap-4 text-sm">
                <div className="rounded-xl bg-lightGray px-2 py-2 text-sm">
                  Code Quality
                </div>
                <div className="rounded-xl bg-lightGray px-2 py-2 text-sm">
                  Scalability
                </div>
                <div className="rounded-xl bg-lightGray px-2 py-2 text-sm">
                  Real Time Communication
                </div>
                <div className="rounded-xl bg-lightGray px-2 py-2 text-sm">
                  API Design & Security
                </div>
              </div>
            </div>
            {/* <!-- right --> */}
            <div className="flex-1 px-2 flex flex-col gap-2">
              <div className="grid grid-cols-2 items-center">
                <div className="text-dimGray text-sm">Role</div>
                <div className="flex justify-between items-center">
                  <div className="text-sm">Full Stack</div>
                  <button className="flex gap-2 items-center  border border-royalBlue text-royalBlue px-2 py-1 rounded-full">
                    <div className="text-sm">Swap</div>
                    <div>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3354 7.33333L14.0021 4.66667L11.3354 2"
                          stroke="#4D80F4"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.002 4.6665H6.00195"
                          stroke="#4D80F4"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.66862 13.9998L2.00195 11.3332L4.66862 8.6665"
                          stroke="#4D80F4"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.002 11.3335H2.00195"
                          stroke="#4D80F4"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-2 items-center">
                <div className="text-dimGray text-sm">Level</div>
                <div className="text-sm">Advanced</div>
              </div>
              <div className="grid grid-cols-2 items-center">
                <div className="text-dimGray text-sm"> Languages</div>
                <div className="text-sm">
                  React js/Node.js,Vue.js,React js/Node.js,Vue.js,React
                  js/Node.js,Vue.js
                </div>
              </div>
              <div className="grid grid-cols-2 items-center">
                <div className="text-dimGray text-sm">Time</div>
                <div className="text-sm">30 mins</div>
              </div>
            </div>
          </div>)}
        </div> 
        <div>
          {/* <!-- assigned students --> */}
          <div className="flex items-center justify-between bg-whisperGray px-4 py-2 rounnded-md">
            <div className="text-sm">Assigned Students(50)</div>
            <div onClick={() => toggleDown("assignStudent")}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.00195 7.5L10.002 12.5L15.002 7.5"
                  stroke="#191C45"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};
export default AssessmentDetails;
