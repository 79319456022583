import React, { useState, useEffect } from "react";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";


import useDebounce from "../../hooks/useDebounce";
import useStore from "../../store/useStore";
import ReportCardGenerateapi from "../../apis/ReportCardGenerateApi";

import { useToast } from "../ui/use-toast";
import { ArrowUpDown, ChevronDown } from "lucide-react";

import { Button } from "./button";
import { Checkbox } from "./checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { Input } from "./input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./table";

// Define the column helper
const columnHelper = createColumnHelper();

export const columns = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        indeterminate={table.getIsSomePageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  }),

  columnHelper.accessor((row) => row.user.name, {
    id: "name",
    header: () => <div className="text-center px-1">Name</div>,
    cell: (info) => <div className="text-center  whitespace-nowrap px-1">{info.getValue()}</div>,
  }),

  columnHelper.accessor((row) => row.user.email, {
    id: "email",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Email <ArrowUpDown />
      </Button>
    ),
    cell: (info) => <div className="lowercase">{info.getValue()}</div>,
    filterFn: "includesString",
  }),

  columnHelper.accessor("exam.name", {
    id: "exam",
    header: "Exam",
    cell: (info) => <div>{info.getValue()}</div>,
  }),
];

export function DataTableDemo({ shortlistedArray }) {


  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [ownerEmail, setOwnerEmail] = useState("");
  const token = useStore((state) => state.token);
  const { toast } = useToast();
  const debounceOwnerEmail = useDebounce(ownerEmail).trim();

const calculateGroupedEmails = (rows) => {
  return rows.reduce((acc, row) => {
    const examId = row.original.exam.id; // Adjust based on your data structure
    const email = row.original.user.email; // Adjust based on your data structure

    if (!acc[examId]) {
      acc[examId] = [];
    }
    acc[examId].push(email);

    return acc;
  }, {});
};

// Recalculate selected emails whenever `rowSelection` changes
useEffect(() => {
  const selectedRows = table.getSelectedRowModel().rows; // Get current selected rows
  const groupedEmails = calculateGroupedEmails(selectedRows);
  setSelectedEmails(groupedEmails);
}, [rowSelection]); 

  const sendReport = async ({ debounceOwnerEmail, selectedEmails,examId }) => {
    try {
      const response = await ReportCardGenerateapi.fetch({
        token,
        examId,
        debounceOwnerEmail,
        selectedEmails,
      });
       
        if (response.status === 200) {
          toast({
            title: "Success",
            description: "Report cards sent successfully.",
            variant: "default",
            duration: 2000,
          });
        } else {
          toast({
            title: "Error",
            description: "Interviewer is Not Part of Organization",
            variant: "destructive",
            duration: 3000,
          });
        }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!debounceOwnerEmail) {
      toast({
        title: "Error",
        description: "Interviewer Email  is not empty ",
        variant: "destructive",
        duration: 3000,
      });
    } else if (!/^\S+@\S+\.\S+$/.test(debounceOwnerEmail)) {
      toast({
        title: "Error",
        description:"Invalid Interviewer email address",
        variant: "destructive",
        duration: 3000,
      });
  
    } else {
      try {
        if(Object.entries(selectedEmails).length<=0){
         
          toast({
            title: "Error",
            description:"Candidate is not selected",
            variant: "destructive",
            duration: 3000,
          });
         
        }
     for (const [examId, emails] of Object.entries(selectedEmails)) {
    await sendReport({
      examId, // Key as examId
       debounceOwnerEmail, // Single owner email
      selectedEmails: emails, // Array of emails
    });
  }
      } catch (error) {
        toast({
          title: "Error",
          description:error.message,
          variant: "destructive",
          duration: 3000,
        });
       
      }
    }
  };

  const table = useReactTable({
    data: shortlistedArray || [],
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });



  return (
    <div className="w-full">
      {/* Filter and Dropdown */}
      <Input
        type="email"
        placeholder="Enter Interviewer Email address"
        value={ownerEmail}
        onChange={(e) => setOwnerEmail(e.target.value)}
        className="border-grayStroke mt-6"
      />
      <div className="flex items-center py-4 gap-1">
        <Input
          placeholder="Filter emails..."
          value={table.getColumn("email")?.getFilterValue() ?? ''}
          onChange={(e) =>
            table.getColumn("email")?.setFilterValue(e.target.value)
          }
          className="max-w-sm"
        />
        <Input
          placeholder="Filter exam..."
          value={table.getColumn("exam")?.getFilterValue() ?? ''}
          onChange={(e) =>
            table.getColumn("exam")?.setFilterValue(e.target.value)
          }
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table.getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) =>
                    column.toggleVisibility(!!value)
                  }
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

     

      {/* Table */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="text-center whitespace-nowrap">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="text-center whitespace-nowrap">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
   

      {/* Pagination */}
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
         
      </div>
      <div className="flex justify-end my-4">
  <Button onClick={handleSubmit}>Share Report Card</Button>
</div>

    </div>
  );
}



