import React from 'react';

const BlockMobileAccess= () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-bold text-red-600 mb-4">
          Desktop Only Website
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          This Report Card page is not accessible on mobile devices.
        </p>
        <p className="text-sm text-gray-500">
          Please visit this page from a desktop or tablet device.
        </p>
      </div>
    </div>
  );
};

export default BlockMobileAccess;