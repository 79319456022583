import axios from 'axios';

const show = async ({ token, userId, examId }) => {
    const url = `${process.env.REACT_APP_PUBLIC_URL}/exam/${examId}/report?userId=${userId}`;
    if (!token || !examId || !userId) {
      throw new Error("No access token found/ExamId/userId");
    }
  
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching exam details:", error);
      throw error;
    }
}
const ReportCardApi={show};
export default ReportCardApi;
