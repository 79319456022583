import ScoreSection from "./ScoreSection";

const SectionwiseScores= ({
    sections,
  }) => (
    <div className="mt-6 rounded-lg">
      <div className="text-xl mb-2">Section wise Score</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-3">
        {sections.map((section, index) => (
          <ScoreSection
            key={index}
            title={section.title}
            score={section.score}
            items={section.items}
          />
        ))}
      </div>
    </div>
  );
  
  export default SectionwiseScores;