import React from "react";
import BarChart from "./BarChart";
import ProgressPoint from "./ProgressPoint";
import AreaChart from "./AreaChart";
import style from "../../style/dashboard/numberCard.module.css";
import { Card, CardContent, CardDescription, CardHeader } from "../ui/card";
import { cn } from "../../lib/utils";

const NumberCard = ({
  heading,
  description,
  count,
  chart = null,
  data,
  field,
  className
}) => {
  if (!data || !chart) return null;
  return (
    <Card className={cn("py-2 ", className)}>
      <CardHeader className="text-xl font-semibold md:text-nowrap">{heading}</CardHeader>
      <CardContent className="-mt-2.5">
        <CardDescription className="text-base ">{description}</CardDescription>
        <div className={`${style.visualData} mt-4`}>
          <h2 className="font-bold text-2xl">{count}</h2>

          {chart && (
            <span className={style.graph}>
              {chart === "Bar" && <BarChart chartData={data} field={field} />}

              {chart === "Progressive Points" && (
                <ProgressPoint
                  min={data.minMeanScore}
                  max={data.maxMeanScore}
                  value={data.averageMeanScore}
                />
              )}
            </span>
          )}
        </div>

        {chart === "Area" && (
          <div className={style.largeGraph}>
            <AreaChart chartData={data} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default NumberCard;
