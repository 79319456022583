import React, { useCallback, useMemo, useState } from "react";
import RecentAssessments from "./RecentAssessments";
import NumberCard from "./NumberCard";
import HiringFunnel from "./HiringFunnel";
import AssessmentPerformance from "./AssessmentPerformance";
import useStore from "../../store/useStore";
import { formatCurrency, getGroupedDate } from "../../lib/utils";
import Filter from "./Filter";
import useDashboardData from "../../hooks/useDashboardData";
import Loading from "../../common/Loading";
import { Navigate } from "react-router-dom";
import { subDays } from "date-fns";

const Dashboard = () => {
  const token = useStore((state) => state.token);
  const [dateFilter, setDateFilter] = useState({
    from: subDays(new Date(), 180),
    to: new Date(),
  });
  const [finalDateFilter, setFinalDateFilter] = useState(dateFilter);

  const handleDateBlur = () => {
    setFinalDateFilter(dateFilter); // Update final state for API call
  };

  const {
    totalCandidates,
    meanScore,
    assessmentTime,
    savedTime,
    isLoading,
    error,
  } = useDashboardData(token, finalDateFilter);

  const numberCards = [
    {
      heading: "Total Candidates",
      description:
        "The total number of candidates that have been in the hiring pipeline",
      count: totalCandidates?.data?.totalAssignedStudents || 0,
      chart: "Bar",
      data: totalCandidates?.data?.groupedData,
      field: "totalCandidates",
    },
    {
      heading: "Average Score",
      description:
        "The Average of all the students that have taken the assessment thus far",
      count: Math.ceil(meanScore?.data?.averageMeanScore || 0),
      chart: "Progressive Points",
      data: {
        minMeanScore: Math.round(meanScore?.data?.minMeanScore || 0),
        averageMeanScore: Math.ceil(meanScore?.data?.averageMeanScore || 0),
        maxMeanScore: Math.round(meanScore?.data?.maxMeanScore || 0),
      },
    },
    {
      heading: "Engineering Hours Saved",
      description:
        "The total number of candidates that have been in the hiring pipeline",
      count: `${Math.round(savedTime?.data?.engineeringHoursSaved || 0)} Hours`,
      chart: "Bar",
      data: savedTime?.data?.groupedData,
      field: "engineeringHoursSaved",
    },
    {
      heading: "Avg. Time Taken Per Assessment",
      description:
        "The total number of candidates that have been in the hiring pipeline",
      count: `${Math.ceil(
        assessmentTime?.data?.averageTimeInHours || 0
      )} Hours`,
      chart: "Bar",
      data: assessmentTime?.data?.groupedData,
      field: "averageTimeInHours",
    },
    {
      heading: "Estimated Engineering Cost Saved",
      description: getGroupedDate(savedTime?.data?.groupedData),
      count: formatCurrency(savedTime?.data?.engineeringCostSaved || 0),
      chart: "Area",
      data: savedTime?.data?.groupedData,
      field: "engineeringCostSaved",
    },
  ];
  if (isLoading) return <Loading />;
  if (error) {
    if (error.response.status === 401) {
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/" />;
    }
  }
  return (
    <div className=" px-4">
      <Filter
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        handleDateBlur={handleDateBlur}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 justify-items-center mt-10 gap-4 w-full">
        <div className={`space-y-4 w-full`}>
          <HiringFunnel
            startTime={finalDateFilter.from}
            endTime={finalDateFilter.to}
          />
          <RecentAssessments />
        </div>

        <div className={`space-y-4 w-full `}>
          {numberCards.slice(0, 4).map((card, index) => (
            <NumberCard
              key={index}
              heading={card.heading}
              description={card.description}
              count={card.count}
              chart={card.chart}
              data={card.data || null}
              field={card.field}
            />
          ))}
        </div>
        <div className={` space-y-4 w-full`}>
          <NumberCard
            className={"py-0 lg:h-[420px]"}
            heading={numberCards[4].heading}
            description={numberCards[4].description}
            count={numberCards[4].count}
            chart={numberCards[4].chart}
            data={numberCards[4].data || null}
            field={numberCards[4].field}
          />
          <AssessmentPerformance
            startTime={finalDateFilter.from}
            endTime={finalDateFilter.to}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
