import axios from 'axios';

const show=async({examId,token})=>{
    const url=`${process.env.REACT_APP_PUBLIC_URL}/exam/${examId}/generateLink`;
    try {
        const response=await axios.post(url,
            {},
            {
        
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                  }
            
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

const copyLinkApis={show};
export default copyLinkApis;