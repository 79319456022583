import SkillBar from "./SkillBar";

const SkillwiseScores= ({ skills }) => (
    <div className="mt-6 rounded-lg">
      <div className="text-xl mb-2">Skill wise Score</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        {skills.map((skill, index) => (
          <SkillBar
            key={index}
            value={skill.value}
            title={skill.title}
            markers={skill.markers}
          />
        ))}
      </div>
    </div>
  );
  
  export default SkillwiseScores;