import React from "react";
import { LuClipboardList } from "react-icons/lu";
import style from "../../style/dashboard/recentAssessments.module.css";
import { useQuery } from "react-query";
import DashboardApi from "../../apis/dashboard/index";
import useStore from "../../store/useStore";
import { Navigate } from "react-router-dom";
import Loading from "../../common/Loading";
import { Card, CardContent, CardHeader } from "../ui/card";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

const RecentAssessments = () => {
  const navigate = useNavigate();
  const token = useStore((state) => state.token);
  const {
    data: RECENT_ASSESSMENT_DATA,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["RECENT_ASSESSMENT_DATA"],
    queryFn: () => DashboardApi.getAssessments({ token }),
  });
  if (isLoading) return <Loading />;
  if (error) return <Navigate to="/" />;
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <h3 className="font-semibold text-xl">Recent Assessments</h3>
          <button
            className="text-[#4D80F4] font-semibold"
            onClick={() => navigate("/assessments")}
          >
            View All
          </button>
        </div>
      </CardHeader>
      <CardContent className="h-[388px]">
        <div className={style.data}>
          {RECENT_ASSESSMENT_DATA?.data?.slice(0, 5)?.map((item, index) => {
            return (
              <div className={style.content} key={index}>
                <div className={style.name}>
                  <h4 className="line-clamp-1" title={item.examName}>
                    {item.examName}
                  </h4>

                  <Link to={`/assessments/exam/${item.examId}/candidate`}>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <button variant="outline">
                            <LuClipboardList
                              size={18}
                              color="rgba(77, 128, 244, 1)"
                            />
                          </button>
                        </TooltipTrigger>
                        <TooltipContent className="bg-white text-black border">
                          <p>View Candidates</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </Link>
                </div>

                <div className={style.status}>
                  <div>
                    <span
                      className={
                        item?.examStatus === "Published" && style.textGreen
                      }
                    >
                      {item.examStatus}
                    </span>

                    <span>{item.date}</span>
                  </div>

                  <div>
                    <span>{item.totalAssigned ?? "No"} Students Assigned</span>

                    {item.totalAssigned && (
                      <span>
                        {item.totalAttended > 0
                          ? `${item.totalAttended} attended`
                          : `${item.totalShortlisted} Shortlisted`}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {RECENT_ASSESSMENT_DATA?.data?.length === 0 && (
          <div className="flex h-full  items-center justify-center font-semibold">
            <p>No assignment found! </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default RecentAssessments;
