import {create} from 'zustand';
import {devtools,persist} from "zustand/middleware";

const useStore=create(persist(
    devtools((set)=>({
        token:null,
        setToken:(token)=>set({token}),
        clearToken:()=>set({token:null}),
    })),
    {
        name:'token',
        getStorage:()=>sessionStorage,
    }
));
export default useStore;