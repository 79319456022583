import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserPlus,
  Users,
  Copy,
  MoreVertical,
  Eye,
  Download,
  Share2,
  Lock,
} from "lucide-react";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../ui/hover-card";
import { Badge } from "../ui/badge";
import { useToast } from "../ui/use-toast";
import moment from "moment";

const AssessmentCard = ({ assessment, onCopyLink, showTime = false }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { toast } = useToast();

  const handleLockedFeature = () => {
    toast({
      title: "Feature Locked",
      description: "Please contact admin to enable this feature.",
      variant: "destructive",
    });
  };

  return (
    <div
      className="group relative rounded-lg border bg-card text-card-foreground shadow-sm transition-all duration-200 hover:shadow-md"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="p-6">
        <div className="flex items-center justify-between">
          {/* Title and Meta Section */}
          <div className="space-y-1.5">
            <HoverCard>
              <HoverCardTrigger>
                <h3 className="font-semibold hover:text-blue-600 cursor-pointer">
                  {assessment.examName}
                </h3>
              </HoverCardTrigger>
              <HoverCardContent className="w-80">
                <div className="space-y-2">
                  <h4 className="text-sm font-semibold">
                    {assessment.examName}
                  </h4>
                  <div className="text-sm text-muted-foreground">
                    Created by {assessment.createdBy} on{" "}
                    {moment(assessment.createdAt).format("MMM Do, YYYY")}
                  </div>
                  <div className="flex items-center gap-2">
                    <Badge variant="secondary">
                      {assessment.totalAssigned} Assigned
                    </Badge>
                    <Badge variant="secondary">
                      {assessment.totalAttended} Attended
                    </Badge>
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>

            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <span>
                {showTime
                  ? moment(assessment.createdAt).format("h:mm A")
                  : moment(assessment.createdAt).format("Do MMM YYYY")}
              </span>
              <span>•</span>
              <span>Created by {assessment.createdBy}</span>
              <span>•</span>
              <span>Role: {assessment.examRole || "Unassigned"}</span>
            </div>
          </div>

          {/* Stats and Actions */}
          <div className="flex items-center gap-6">
            {/* Stats */}
            <div className="flex items-center gap-4">
              <div className="flex flex-col items-center px-3 py-1 rounded-md bg-secondary/20">
                <span className="text-lg font-semibold">
                  {assessment.totalAttended || 0}
                </span>
                <span className="text-xs text-muted-foreground">Attended</span>
              </div>
              <div className="flex flex-col items-center px-3 py-1 rounded-md bg-secondary/20">
                <span className="text-lg font-semibold">
                  {assessment.totalAssigned || 0}
                </span>
                <span className="text-xs text-muted-foreground">Assigned</span>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() =>
                  navigate(`exam/${assessment.examId}/assigncandidate`, {
                    state: { title: assessment.examName },
                  })
                }
                className="gap-2"
              >
                <UserPlus className="h-4 w-4" />
                <span
                  className={`${
                    isHovered ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-200`}
                >
                  Assign
                </span>
              </Button>

              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  navigate(`exam/${assessment.examId}/candidate`, {
                    state: {
                      title: assessment.examName,
                    },
                  });
                }}
                className="gap-2"
              >
                <Users className="h-4 w-4" />
                <span
                  className={`${
                    isHovered ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-200`}
                >
                  View
                </span>
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm">
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[200px]">
                  <DropdownMenuItem
                    onClick={() => onCopyLink(assessment.examId)}
                  >
                    <Copy className="h-4 w-4 mr-2" />
                    Copy Link
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="text-muted-foreground cursor-not-allowed"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLockedFeature();
                    }}
                  >
                    <Eye className="h-4 w-4 mr-2" />
                    Preview
                    <Lock className="h-3 w-3 ml-auto" />
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="text-muted-foreground cursor-not-allowed"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLockedFeature();
                    }}
                  >
                    <Download className="h-4 w-4 mr-2" />
                    Export Results
                    <Lock className="h-3 w-3 ml-auto" />
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="text-muted-foreground cursor-not-allowed"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLockedFeature();
                    }}
                  >
                    <Share2 className="h-4 w-4 mr-2" />
                    Share
                    <Lock className="h-3 w-3 ml-auto" />
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentCard;
