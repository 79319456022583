import useStore from "../../store/useStore";
import { useQuery, useQueryClient } from "react-query";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import candidateListApi from "../../apis/candidatelist";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, NavLink } from "react-router-dom";
import NoData from "../../common/NoData";
import useDebounce from "../../hooks/useDebounce";
import ShortlistedApi from "../../apis/Shortlisted";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Card } from "../ui/card";
import { Badge } from "../ui/badge";
import {
  ChevronDown,
  Search,
  ChevronLeft,
  Check,
  X,
  FileText,
  UserPlus,
} from "lucide-react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../ui/select";
import {
  format,
  formatDistanceToNow,
  isWithinInterval,
  subDays,
  parseISO,
} from "date-fns";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { TableToolbar } from "./TableToolbar";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";
import { useToast } from "../ui/use-toast";
import { ReportCardGenerate } from "./CandidateListShortlist";

const CandidateList = () => {
  const [mainTab, setMainTab] = useState("all");
  const [subFilter, setSubFilter] = useState("all");
  const location = useLocation();
  const { title, icon } = location.state || {};
  const { examId } = useParams();
  const token = useStore((state) => state.token);
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("");
  const queryClient = useQueryClient();
  const [showExamDropdown, setShowExamDropdown] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState(
    examId ? parseInt(examId) : null
  );
  const [page, setPage] = useState(1);
  const [searchname, setSearchName] = useState("");
  const [searchemail, setsearchEmail] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [scoreFilter, setScoreFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("all");
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });
  const PAGE_SIZE = 25;
  const [shortlistFilter, setShortlistFilter] = useState("all");
  const [examFilter, setExamFilter] = useState("all");
  const [visibleColumns, setVisibleColumns] = useState({
    email: true,
    name: true,
    exam: true,
    status: true,
    score: true,
    date: true,
    actions: true,
  });
  const [length,setLength]=useState({
    assignLength:0,
    completedLength:0,
    shortlistedLength:0,
  })
  const { toast } = useToast();
  const fetchCandidatelist = async () => {
    const reposnse = await candidateListApi.show(token);
    return reposnse.data;
  };
  const handleBackClick = () => {
    navigate("/assessments");
  };
  const { data, isLoading, isError } = useQuery({
    queryKey: ["candidatelist", examId],
    queryFn: fetchCandidatelist,
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
  });
  const changeStatus = useCallback(
    async (examUserId, status) => {
      try {
        await ShortlistedApi.update({ examUserId, token, newStatus: status });
        toast({
          title: "Status Updated",
          description: `Candidate ${status.toLowerCase()} successfully!`,
          variant: "default",
        });
        queryClient.invalidateQueries(["candidatelist", examId]);
      } catch (error) {
        toast({
          title: "Update Failed",
          description: "Failed to update candidate status. Please try again.",
          variant: "destructive",
        });
      }
    },
    [token, examId, queryClient, toast]
  );
  const debounceSearchKey = useDebounce(searchKey);

  const filteredCandidates = useMemo(() => {
    if (!data || !selectedExamId) return data;
    return data.filter((candidate) => candidate.exam?.id === selectedExamId);
  }, [data, selectedExamId]);

  const fetchCandidateList = useMemo(() => {
    if (!filteredCandidates) return []; // Handle case where data is null or undefined

    let filteredData = [...filteredCandidates]; // Create a new array to avoid mutating the original

    // Handle search filtering
    if (debounceSearchKey.trim()) {
      const searchTerm = debounceSearchKey.trim().toLowerCase();
      const isEmailSearch = searchemail && searchTerm.includes("@");
      setPage(1);
      filteredData = filteredData.filter((candidate) => {
        if (isEmailSearch) {
          return candidate.user?.email?.toLowerCase().includes(searchTerm);
        }
        return candidate.user?.name?.toLowerCase().includes(searchTerm);
      });
    }

    return filteredData;
  }, [filteredCandidates, debounceSearchKey]);

  const { 
    allArray, 
    assignedArray, 
    completedArray, 
    shortlistedArray, 
    filteredData ,
    ReportCardGenerated
  } = useMemo(() => {
    const candidates = [...fetchCandidateList];
  
    if (!candidates || candidates === "user is not a part of organization") {
      return {
        allArray: [],
        assignedArray: [],
        completedArray: [],
        shortlistedArray: [],
        filteredData: [],
        ReportCardGenerated:[],
      };
    }
  
    // Pre-filtered groups
    const assignedArray = candidates.filter((elem) =>
      ["enrolled", "Mail Sent"].includes(elem.examStatus)
    );
    const completedArray = candidates.filter(
      (elem) => elem.examStatus === "ended"
    );
    const shortlistedArray = candidates.filter(
      (elem) =>
        elem.shortlistStatus === "Shortlisted" && elem.examStatus === "ended"
    );
    const ReportCardGenerated=candidates.filter(
      (elem)=> Math.round(elem.marksScored) > 0 && elem.examStatus === "ended"
    )
  setPage(1);
    let filteredData = [];
    switch (mainTab) {
      case "assigned":
        filteredData = assignedArray;
        break;
      case "completed":
        filteredData = completedArray;
        break;
      case "shortlisted":
        filteredData = shortlistedArray;
        break;
      case "attempted":
        filteredData = completedArray.filter(
          (elem) => Math.round(elem.marksScored) > 0
        );
        break;
      case "pending":
        filteredData = completedArray.filter(
          (elem) => elem.marksScored === null
        );
        break;
      case "not-attempted":
        filteredData = completedArray.filter(
          (elem) => elem.marksScored === 0
        );
        break;
      default:
        filteredData = candidates; // Default is all candidates
        break;
    }
  
    return {
      allArray: candidates,
      assignedArray,
      completedArray,
      shortlistedArray,
      filteredData,
      ReportCardGenerated
    };
  }, [fetchCandidateList, mainTab]);
  
  

  const statusColor = useCallback((value) => {
    if (value < 30) {
      return "text-lightRed";
    } else if (value < 80) {
      return "text-goldenBrown";
    } else {
      return "text-forestGreen";
    }
  }, []);

  // Get unique exam IDs and their details
  const uniqueExams = useMemo(() => {
    if (!data) return [];
    const examsMap = new Map();
    let examData = [...data];
    if (title) {
      examData = examData.filter((elem) => elem.exam.id === parseInt(examId));
    }
    examData.forEach((item) => {
      if (item.exam && !examsMap.has(item.exam.id)) {
        examsMap.set(item.exam.id, {
          id: item.exam.id,
          name: item.exam.name,
          benchMarkScore: item.exam.benchMarkScore,
        });
      }
    });
    return Array.from(examsMap.values());
  }, [data]);

  // Get the exams to show in dropdown based on whether there's a title
  const dropdownExams = useMemo(() => {
    if (!uniqueExams) return [];

    // Create an "All Exam" option
    const allExamOption = { id: null, name: "All Exam" };

    if (title) {
      // If title exists, show "All Exam" and the exam matching the URL examId
      const matchingExam = uniqueExams.find(
        (exam) => exam.id === parseInt(examId)
      );
      return matchingExam ? [matchingExam] : [allExamOption];
    }
    // If no title, show "All Exam" and all exams
    return [allExamOption, ...uniqueExams];
  }, [uniqueExams, examId, title]);

  const getDropdownDisplayText = () => {
    if (!selectedExamId) return "All Exam";
    const selectedExam = uniqueExams.find((exam) => exam.id === selectedExamId);
    return selectedExam ? `${selectedExam.name}` : "All Exam";
  };

  const filterByDate = useCallback(
    (data) => {
      if (dateFilter === "all") return data;

      const now = new Date();
      return data.filter((candidate) => {
        if (!candidate.user?.createdAt) return false;
        const createdDate = parseISO(candidate.user.createdAt);

        switch (dateFilter) {
          case "today":
            return isWithinInterval(createdDate, {
              start: subDays(now, 1),
              end: now,
            });
          case "week":
            return isWithinInterval(createdDate, {
              start: subDays(now, 7),
              end: now,
            });
          case "month":
            return isWithinInterval(createdDate, {
              start: subDays(now, 30),
              end: now,
            });
          default:
            return true;
        }
      });
    },
    [dateFilter]
  );

  // Sorting function
  const sortData = useCallback(
    (data) => {
      if (!sortConfig.key) return data;

      return [...data].sort((a, b) => {
        if (sortConfig.key === "score") {
          const scoreA = a.marksScored || 0;
          const scoreB = b.marksScored || 0;
          return sortConfig.direction === "asc"
            ? scoreA - scoreB
            : scoreB - scoreA;
        }
        // ... other sort cases ...
        return 0;
      });
    },
    [sortConfig]
  );

  // Add exam filtering to the filter chain
  const filterByExam = useCallback(
    (data) => {
      if (examFilter === "all") return data;
      return data.filter(
        (candidate) => candidate.exam?.id.toString() === examFilter
      );
    },
    [examFilter]
  );

  // Update getPaginatedData to include exam filtering
  const getPaginatedData = (data) => {
    const statusFiltered = filterByStatus(data);
    const scoreFiltered = filterByScore(statusFiltered);
    const dateFiltered = filterByDate(scoreFiltered);
    const examFiltered = filterByExam(dateFiltered);
    const shortlistFiltered = filterByShortlistStatus(examFiltered);
    const sorted = sortData(shortlistFiltered);
    const start = (page - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    return sorted.slice(start, end);
  };

  // Sort handler
  const requestSort = (key) => {
    setSortConfig((current) => ({
      key,
      direction:
        current.key === key && current.direction === "desc" ? "asc" : "desc",
    }));
  };

  // Add this function to filter by status
  const filterByStatus = useCallback(
    (data) => {
      if (statusFilter === "all") return data;

      return data.filter((candidate) => {
        switch (statusFilter) {
          case "assigned":
            return ["enrolled", "Mail Sent"].includes(candidate.examStatus);
          case "started":
            return candidate.examStatus === "started";
          case "report-pending":
            return (
              candidate.examStatus === "ended" && candidate.marksScored === null
            );
          case "not-attempted":
            return (
              candidate.examStatus === "ended" && candidate.marksScored === 0
            );
          case "report-generated":
            return (
              candidate.examStatus === "ended" && candidate.marksScored > 0
            );
          default:
            return true;
        }
      });
    },
    [statusFilter]
  );

  const filterByScore = useCallback(
    (data) => {
      if (scoreFilter === "all") return data;

      return data.filter((candidate) => {
        const score = candidate.marksScored;
        if (!score || score <= 0) return false;

        switch (scoreFilter) {
          case "low":
            return score < 40;
          case "medium":
            return score >= 40 && score < 80;
          case "high":
            return score >= 80;
          default:
            return true;
        }
      });
    },
    [scoreFilter]
  );

  const filterByShortlistStatus = useCallback(
    (data) => {
      if (shortlistFilter === "all") return data;

      return data.filter((candidate) => {
        switch (shortlistFilter) {
          case "shortlisted":
            return candidate.shortlistStatus === "Shortlisted";
          case "rejected":
            return candidate.shortlistStatus === "Rejected";
          default:
            return true;
        }
      });
    },
    [shortlistFilter]
  );

  // Toolbar action handlers
  const handleExport = async (type) => {
    try {
      // Implement export logic
      toast.success(`Exporting as ${type.toUpperCase()}`);
    } catch (error) {
      toast.error("Export failed");
    }
  };

  const totalPages = Math.ceil(filteredData.length / PAGE_SIZE);

  const renderPaginationItems = () => {
    const items = [];
    const maxVisible = 5; // Number of page numbers to show

    let start = Math.max(1, page - Math.floor(maxVisible / 2));
    let end = Math.min(start + maxVisible - 1, totalPages);

    if (end - start + 1 < maxVisible) {
      start = Math.max(1, end - maxVisible + 1);
    }

    // Add first page
    if (start > 1) {
      items.push(
        <PaginationItem key="1">
          <PaginationLink onClick={() => setPage(1)}>1</PaginationLink>
        </PaginationItem>
      );
      if (start > 2) {
        items.push(
          <PaginationItem key="ellipsis1">
            <PaginationEllipsis />
          </PaginationItem>
        );
      }
    }

    // Add pages
    for (let i = start; i <= end; i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink isActive={page === i} onClick={() => setPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Add last page
    if (end < totalPages) {
      if (end < totalPages - 1) {
        items.push(
          <PaginationItem key="ellipsis2">
            <PaginationEllipsis />
          </PaginationItem>
        );
      }
      items.push(
        <PaginationItem key={totalPages}>
          <PaginationLink onClick={() => setPage(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorPage />;
  if (!data || data.data === "user is not a part of organization")
    return <NoData />;

  return (
    <div className="w-full min-h-screen flex flex-col">
      {/* Full Width Header Section */}
      <div className="w-full border-b bg-gray-50/40">
        <div className="max-w-[1800px] mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              {icon && (
                <Button variant="ghost" size="sm" onClick={handleBackClick}>
                  <ChevronLeft className="h-4 w-4" />
                </Button>
              )}
              <div className="mt-5">
                <h1 className="text-2xl font-semibold">Candidate List</h1>
                <p className="text-sm text-muted-foreground mt-1">
                  {filteredData.length} candidates
                </p>
              </div>
              {title && (
                <div className="flex items-center gap-2">
                  <span className="text-muted-foreground">—</span>
                  <span className="text-xl font-medium">{title}</span>
                </div>
              )}
            </div>

            <div className="flex items-center gap-3">
              <Button
                onClick={() =>
                  navigate(
                    `/assessments/exam/${selectedExamId}/assigncandidate`
                  )
                }
                disabled={!selectedExamId}
                className="gap-2"
              >
                <UserPlus className="h-4 w-4" />
                Assign Candidates
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="min-w-[200px] justify-between font-normal"
                  >
                    {getDropdownDisplayText()}
                    <ChevronDown className="h-4 w-4 opacity-50" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[200px]">
                  {!title && (
                    <DropdownMenuItem onSelect={() => setSelectedExamId(null)}>
                      All Exam
                    </DropdownMenuItem>
                  )}
                  {uniqueExams.map((exam) => (
                    <DropdownMenuItem
                      key={exam.id}
                      onSelect={() => {
                        setSelectedExamId(exam.id);
                      }}
                    >
                      {exam.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="flex-1 max-w-[1800px] mx-auto w-full px-6 py-6">
        {/* Primary Filters */}
        <div className="flex items-center justify-between">
        <div className="flex gap-2 mb-6">
          <Button
            variant={mainTab === "all" ? "default" : "outline"}
            className="min-w-[140px]"
            onClick={() => setMainTab("all")}
          >
            All
            <span className="ml-2 text-xs font-normal">
              ({allArray.length})
            </span>
          </Button>
          <Button
            variant={mainTab === "assigned" ? "default" : "outline"}
            className="min-w-[140px]"
            onClick={() => setMainTab("assigned")}
          >
            Assigned
            <span className="ml-2 text-xs font-normal">
              ({assignedArray.length})
            </span>
          </Button>
          <Button
            variant={mainTab === "completed" ? "default" : "outline"}
            className="min-w-[140px]"
            onClick={() => setMainTab("completed")}
          >
            Completed
            <span className="ml-2 text-xs font-normal">
              ({completedArray.length})
            </span>
          </Button>
          <Button
            variant={mainTab === "shortlisted" ? "default" : "outline"}
            className="min-w-[140px]"
            onClick={() => setMainTab("shortlisted")}
          >
            Shortlisted
            <span className="ml-2 text-xs font-normal">
              ({shortlistedArray.length})
            </span>
          </Button>
        </div>
    <div className="mb-6">
         <ReportCardGenerate  shortlistedArray={ ReportCardGenerated}/>
         </div>
      
        </div>

        {/* Table Section */}
        <div className="rounded-md border bg-white">
          {/* <TableToolbar
            onExport={handleExport}
            onSendEmail={() => toast.success("Email feature coming soon")}
          /> */}

          <Table>
            <TableHeader className="sticky top-0 bg-white">
              <TableRow>
                <TableHead className="w-[250px]">
                  <div className="flex flex-col gap-2 py-4">
                    <span className="text-sm font-medium text-center whitespace-nowrap">
                      Email
                    </span>
                    <div className="relative">
                      <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                      <Input
                        placeholder="Filter email..."
                        value={searchemail}
                        onChange={(e) => {
                          setsearchEmail(e.target.value);
                          setSearchKey(e.target.value);
                        }}
                        className="pl-8 h-8 text-sm"
                      />
                    </div>
                  </div>
                </TableHead>

                <TableHead className="w-[200px]">
                  <div className="flex flex-col gap-2 py-4">
                    <span className="text-sm font-medium text-center whitespace-nowrap">
                      Name
                    </span>
                    <div className="relative">
                      <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                      <Input
                        placeholder="Filter name..."
                        value={searchname}
                        onChange={(e) => {
                          setSearchName(e.target.value);
                          setSearchKey(e.target.value);
                        }}
                        className="pl-8 h-8 text-sm"
                      />
                    </div>
                  </div>
                </TableHead>

                {!selectedExamId && (
                  <TableHead className="w-[200px]">
                    <div className="flex flex-col gap-2 py-4">
                      <span className="text-sm font-medium text-center whitespace-nowrap">
                        Exam
                      </span>
                      <Select
                        onValueChange={(value) => {
                          setPage(1);
                          setExamFilter(value);
                        }}
                        defaultValue="all"
                      >
                        <SelectTrigger className="h-8 text-sm">
                          <SelectValue placeholder="Filter exam" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">All Exams</SelectItem>
                          {uniqueExams.map((exam) => (
                            <SelectItem
                              key={exam.id}
                              value={exam.id.toString()}
                            >
                              {exam.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </TableHead>
                )}

                <TableHead className="w-[180px]">
                  <div className="flex flex-col gap-2 py-4">
                    <span className="text-sm font-medium text-center">
                      Status
                    </span>
                    <Select
                      onValueChange={(value) => {
                        setPage(1);
                        setStatusFilter(value);
                      }}
                      defaultValue="all"
                    >
                      <SelectTrigger className="h-8 text-sm">
                        <SelectValue placeholder="Filter status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All</SelectItem>
                        <SelectItem value="assigned">Assigned</SelectItem>
                        <SelectItem value="started">Started</SelectItem>
                        <SelectItem value="report-pending">
                          Report Card Pending
                        </SelectItem>
                        <SelectItem value="not-attempted">
                          Not Attempted
                        </SelectItem>
                        <SelectItem value="report-generated">
                          Report Card Generated
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </TableHead>

                <TableHead className="w-[100px]">
                  <div className="flex flex-col gap-2 py-4">
                    <div
                      className="flex items-center justify-center gap-2 cursor-pointer"
                      onClick={() => requestSort("score")}
                    >
                      <span className="text-sm font-medium text-center whitespace-nowrap">
                        Score
                      </span>
                      {sortConfig.key === "score" && (
                        <ChevronDown
                          className={cn("h-4 w-4 transition-transform", {
                            "transform rotate-180":
                              sortConfig.direction === "asc",
                          })}
                        />
                      )}
                    </div>
                    <Select
                      onValueChange={(value) => {
                        setScoreFilter(value);
                        setPage(1);
                      }}
                      defaultValue="all"
                    >
                      <SelectTrigger className="h-8 text-sm">
                        <SelectValue placeholder="Filter score" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Scores</SelectItem>
                        <SelectItem value="low">Below 40</SelectItem>
                        <SelectItem value="medium">40 - 80</SelectItem>
                        <SelectItem value="high">Above 80</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </TableHead>

                <TableHead className="w-[180px]">
                  <div className="flex flex-col gap-2 py-4">
                    <div
                      className="flex items-center justify-center gap-2 cursor-pointer"
                      onClick={() => requestSort("date")}
                    >
                      <span className="text-sm font-medium text-center whitespace-nowrap">
                        Assigned Date
                      </span>
                      {/* {sortConfig.key === "date" && (
                        <ChevronDown
                          className={cn("h-4 w-4 transition-transform", {
                            "transform rotate-180":
                              sortConfig.direction === "asc",
                          })}
                        />
                      )} */}
                    </div>
                    <Select
                      onValueChange={(value) => {
                        setPage(1);
                        setDateFilter(value);
                      }}
                      defaultValue="all"
                    >
                      <SelectTrigger className="h-8 text-sm">
                        <SelectValue placeholder="Filter date" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Time</SelectItem>
                        <SelectItem value="today">Today</SelectItem>
                        <SelectItem value="week">This Week</SelectItem>
                        <SelectItem value="month">This Month</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </TableHead>

                <TableHead className="w-[200px]">
                  <div className="flex flex-col gap-2 py-4">
                    <span className="text-sm font-medium text-center whitespace-nowrap">
                      Actions
                    </span>
                    <Select
                      onValueChange={(value) => {
                        setShortlistFilter(value);
                        setPage(1);
                      }}
                      defaultValue="all"
                    >
                      <SelectTrigger className="h-8 text-sm">
                        <SelectValue placeholder="Filter actions" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All</SelectItem>
                        <SelectItem value="shortlisted">Shortlisted</SelectItem>
                        <SelectItem value="rejected">Rejected</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getPaginatedData(filteredData).map((candidate) => (
                <TableRow
                  key={candidate.id}
                  className="hover:bg-muted/50 transition-colors"
                >
                  <TableCell className="text-center whitespace-nowrap">
                    {candidate.user?.email}
                  </TableCell>
                  <TableCell className="text-center whitespace-nowrap">
                    <div className="flex items-center gap-2 justify-center">
                      <span>{candidate.user?.name}</span>
                      {candidate.recommendStatus === "Recommended" && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Check className="h-4 w-4 text-green-600" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Recommended Candidate</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </div>
                  </TableCell>
                  {!selectedExamId && (
                    <TableCell className="text-center whitespace-nowrap">
                      {candidate.exam?.name}
                    </TableCell>
                  )}
                  <TableCell className="text-center whitespace-nowrap">
                    <Badge
                      variant="outline"
                      className={cn("bg-transparent", {
                        "text-blue-600": ["enrolled", "Mail Sent"].includes(
                          candidate.examStatus
                        ),
                        "text-orange-600": candidate.examStatus === "started",
                        "text-green-600":
                          candidate.examStatus === "ended" &&
                          candidate.marksScored > 0,
                        "text-yellow-600":
                          candidate.examStatus === "ended" &&
                          candidate.marksScored === null,
                        "text-red-600":
                          candidate.examStatus === "ended" &&
                          candidate.marksScored === 0,
                      })}
                    >
                      {["enrolled", "Mail Sent"].includes(
                        candidate.examStatus
                      ) && "Assigned"}
                      {candidate.examStatus === "started" && "Started"}
                      {candidate.examStatus === "ended" &&
                        candidate.marksScored === null &&
                        "Report Card Pending"}
                      {candidate.examStatus === "ended" &&
                        candidate.marksScored === 0 &&
                        "Not Attempted"}
                      {candidate.examStatus === "ended" &&
                        candidate.marksScored > 0 &&
                        "Report Card Generated"}
                    </Badge>
                  </TableCell>
                  <TableCell className="text-center whitespace-nowrap">
                    {candidate.examStatus === "ended" &&
                    candidate.marksScored > 0 ? (
                      <span
                        className={cn({
                          "text-red-600": candidate.marksScored < 40,
                          "text-yellow-600":
                            candidate.marksScored >= 40 &&
                            candidate.marksScored < 80,
                          "text-green-600": candidate.marksScored >= 80,
                        })}
                      >
                        {Math.round(candidate.marksScored)}
                      </span>
                    ) : (
                      <span className="text-gray-500">-</span>
                    )}
                  </TableCell>
                  <TableCell className="text-center whitespace-nowrap">
                    {candidate.user?.createdAt && (
                      <span className="text-gray-600">
                        {isWithinInterval(parseISO(candidate.user.createdAt), {
                          start: subDays(new Date(), 7),
                          end: new Date(),
                        })
                          ? formatDistanceToNow(
                              parseISO(candidate.user.createdAt),
                              { addSuffix: true }
                            )
                          : format(
                              parseISO(candidate.user.createdAt),
                              "MMM d, yyyy"
                            )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="text-center whitespace-nowrap">
                    <div className="flex items-center justify-center gap-1">
                      {candidate.examStatus === "ended" && (
                        <>
                          {candidate.shortlistStatus !== "Shortlisted" && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() =>
                                      changeStatus(candidate.id, "Shortlisted")
                                    }
                                    className="h-8 w-8 hover:bg-green-50 dark:hover:bg-green-950"
                                  >
                                    <Check className="h-4 w-4 text-muted-foreground group-hover:text-green-600 transition-colors" />
                                    <span className="sr-only">
                                      Shortlist Candidate
                                    </span>
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent
                                  side="left"
                                  className="flex items-center gap-2"
                                >
                                  <Check className="h-4 w-4 text-green-600" />
                                  <span>Shortlist Candidate</span>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}

                          {candidate.shortlistStatus !== "Rejected" && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() =>
                                      changeStatus(candidate.id, "Rejected")
                                    }
                                    className="h-8 w-8 hover:bg-red-50 dark:hover:bg-red-950"
                                  >
                                    <X className="h-4 w-4 text-muted-foreground group-hover:text-red-600 transition-colors" />
                                    <span className="sr-only">
                                      Reject Candidate
                                    </span>
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent
                                  side="left"
                                  className="flex items-center gap-2"
                                >
                                  <X className="h-4 w-4 text-red-600" />
                                  <span>Reject Candidate</span>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}

                          {candidate.marksScored > 0 && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() =>
                                      navigate(
                                        `/exam/${candidate.exam.id}/user/${candidate.user.id}/report`
                                      )
                                    }
                                    className="h-8 w-8 hover:bg-blue-50 dark:hover:bg-blue-950"
                                  >
                                    <FileText className="h-4 w-4 text-muted-foreground group-hover:text-blue-600 transition-colors" />
                                    <span className="sr-only">
                                      View Report Card
                                    </span>
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent
                                  side="left"
                                  className="flex items-center gap-2"
                                >
                                  <FileText className="h-4 w-4 text-blue-600" />
                                  <span>View Report Card</span>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Pagination */}
          <div className="border-t py-4">
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => setPage((p) => Math.max(1, p - 1))}
                    className={cn(
                      page === 1 && "pointer-events-none opacity-50"
                    )}
                  />
                </PaginationItem>

                {renderPaginationItems()}

                <PaginationItem>
                  <PaginationNext
                    onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
                    className={cn(
                      page === totalPages && "pointer-events-none opacity-50"
                    )}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>

            <div className="text-center text-sm text-muted-foreground mt-2">
              Showing {(page - 1) * PAGE_SIZE + 1} to{" "}
              {Math.min(page * PAGE_SIZE, filteredData.length)} of {filteredData.length}{" "}
              entries
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateList;
