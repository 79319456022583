import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import {
  ChevronDown,
  ChevronRight,
  Search,
  Plus,
  Copy,
  UserPlus,
  Users,
  Lock,
} from "lucide-react";
import { Badge } from "../../components/ui/badge";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

import assessmentList from "../../apis/assessmentListApi";
import useStore from "../../store/useStore";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import copyLinkApis from "../../apis/copyLink";
import toast, { Toaster } from "react-hot-toast";
import roleListApi from "../../apis/roleListApi";
import AssessmentCard from "./AssessmentCard";
import RoleCard from "./RoleCard";

const AssessmentList = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const token = useStore((state) => state.token);
  const navigate = useNavigate();

  // Single implementation of handleCopyLink
  const handleCopyLink = async (examId) => {
    try {
      const data = await copyLinkApis.show({ token, examId });
      await navigator.clipboard.writeText(data?.data?.link);
      toast.success("Link copied to clipboard!");
    } catch (error) {
      console.log(error);
      toast.error("Link not copied");
    }
  };

  // Fetch roles
  const {
    data: rolesData,
    isLoading: rolesLoading,
    isError: rolesError,
  } = useQuery({
    queryKey: ["roleList"],
    queryFn: () => roleListApi.show(token),
  });

  // Fetch assessments
  const {
    data: assessments,
    isLoading: assessmentsLoading,
    isError: assessmentsError,
  } = useQuery({
    queryKey: ["assessmentList", activeTab],
    queryFn: () => assessmentList.show(token, null),
  });

  // Fixed roles combination logic
  const roles = useMemo(() => {
    if (!rolesData || !assessments) return [];

    // Create a map of all roles including those from assessments
    const allRoles = new Map();

    // First, add roles from the roles API
    rolesData.forEach((role) => {
      allRoles.set(role.name, {
        ...role,
        assessments: [],
        stats: {
          totalCandidates: 0,
          activeAssessments: 0,
        },
      });
    });

    // Then process assessments
    assessments.forEach((assessment) => {
      const roleName = assessment.examRole || "Unassigned";

      // If role doesn't exist in our map, create it
      if (!allRoles.has(roleName)) {
        allRoles.set(roleName, {
          name: roleName,
          assessments: [],
          stats: {
            totalCandidates: 0,
            activeAssessments: 0,
          },
        });
      }

      // Get the role and update it
      const role = allRoles.get(roleName);
      role.assessments.push(assessment);
      role.stats.totalCandidates += parseInt(assessment.totalAssigned) || 0;
      role.stats.activeAssessments += 1;
    });

    return Array.from(allRoles.values());
  }, [rolesData, assessments]);

  // Filter based on search for both roles and assessments
  const filteredRoles = useMemo(() => {
    if (!searchQuery.trim()) return roles;

    const query = searchQuery.toLowerCase();
    return roles.filter(
      (role) =>
        role.name?.toLowerCase().includes(query) ||
        role.assessments.some((assessment) =>
          assessment.examName?.toLowerCase().includes(query)
        )
    );
  }, [roles, searchQuery]);

  // Add filtered assessments
  const filteredAssessments = useMemo(() => {
    if (!assessments || !searchQuery.trim()) return assessments;

    const query = searchQuery.toLowerCase();
    return assessments.filter(
      (assessment) =>
        assessment.examName?.toLowerCase().includes(query) ||
        assessment.examRole?.toLowerCase().includes(query) ||
        assessment.createdBy?.toLowerCase().includes(query)
    );
  }, [assessments, searchQuery]);

  // Add this function to group assessments by recency
  const getRecentAssessments = useMemo(() => {
    if (!filteredAssessments)
      return {
        today: [],
        yesterday: [],
        thisWeek: [],
      };

    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const thisWeek = moment().subtract(7, "days").startOf("day");

    // Sort assessments by creation date (newest first) and limit to 6
    const sortedAssessments = [...filteredAssessments]
      .sort(
        (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
      )
      .slice(0, 6);


    const groups = {
      today: [],
      yesterday: [],
      thisWeek: [],
    };

    sortedAssessments.forEach((assessment) => {
      const createdAt = moment(assessment.createdAt);

      if (createdAt.isSame(today, "day")) {
        groups.today.push(assessment);
      } else if (createdAt.isSame(yesterday, "day")) {
        groups.yesterday.push(assessment);
      } else {
        // Changed logic: anything within the week goes to thisWeek
        groups.thisWeek.push(assessment);
      }
    });

    return groups;
  }, [filteredAssessments]);

  // Handle loading and error states
  if (rolesLoading || assessmentsLoading) return <Loading />;
  if (rolesError || assessmentsError) return <ErrorPage />;

  const handleLockedFeature = () => {
    toast.error("Please contact admin to enable this feature");
  };

  return (
    <div className="w-full max-w-[1800px] mx-auto px-6 py-4">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-semibold">Assessments</h1>
        <div className="flex items-center gap-4">
          <div className="relative w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search assessments..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8"
            />
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="outline" className="cursor-not-allowed">
                  <Plus className="h-4 w-4 mr-2" />
                  Create Role
                  <Lock className="h-3 w-3 ml-2" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Contact admin to enable this feature</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button className="cursor-not-allowed">
                  <Plus className="h-4 w-4 mr-2" />
                  Create Assessment
                  <Lock className="h-3 w-3 ml-2" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Contact admin to enable this feature</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      {/* Tabs Navigation */}
      <Tabs defaultValue="roles" className="w-full">
        <TabsList>
          <TabsTrigger value="roles">
            Roles ({filteredRoles.length})
          </TabsTrigger>
          <TabsTrigger value="assessments">
            All Assessments ({filteredAssessments?.length || 0})
          </TabsTrigger>
          <TabsTrigger value="recent">Recent</TabsTrigger>
        </TabsList>

        {/* Roles View */}
        <TabsContent value="roles" className="space-y-4">
          {filteredRoles?.map((role) => (
            <RoleCard key={role.id} role={role} />
          ))}
        </TabsContent>

        {/* Recent Assessments View */}
        <TabsContent value="recent" className="space-y-6">
          {/* Today's Assessments */}
          {getRecentAssessments.today.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-muted-foreground">
                Today
              </h3>
              {getRecentAssessments.today.map((assessment) => (
                <AssessmentCard
                  key={assessment.examId}
                  assessment={assessment}
                  showTime={true}
                  onCopyLink={handleCopyLink}
                />
              ))}
            </div>
          )}

          {/* Yesterday's Assessments */}
          {getRecentAssessments.yesterday.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-muted-foreground">
                Yesterday
              </h3>
              {getRecentAssessments.yesterday.map((assessment) => (
                <AssessmentCard
                  key={assessment.examId}
                  assessment={assessment}
                  showTime={true}
                  onCopyLink={handleCopyLink}
                />
              ))}
            </div>
          )}

          {/* This Week's Assessments */}
          {getRecentAssessments.thisWeek.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-muted-foreground">
                This Week
              </h3>
              {getRecentAssessments.thisWeek.map((assessment) => (
                <AssessmentCard
                  key={assessment.examId}
                  assessment={assessment}
                  showTime={false}
                  onCopyLink={handleCopyLink}
                />
              ))}
            </div>
          )}
        </TabsContent>

        {/* Assessments Tab Content */}
        <TabsContent value="assessments" className="space-y-4">
          {filteredAssessments?.map((assessment) => (
            <AssessmentCard
              key={assessment.examId}
              assessment={assessment}
              onCopyLink={handleCopyLink}
              showTime={false}
            />
          ))}
        </TabsContent>
      </Tabs>
    </div>
  );
};

const getStatusVariant = (status) => {
  switch (status) {
    case "active":
      return "default";
    case "completed":
      return "success";
    case "draft":
      return "secondary";
    default:
      return "default";
  }
};

export default AssessmentList;
