import React from "react";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";

const steps = [
  { label: "Assigned", timestamp: "15 Nov 10:33" },
  { label: "Signed Up", timestamp: "16 Nov 14:45" },
  { label: "Started", timestamp: "16 Nov 15:15" },
  { label: "Ended", timestamp: "16 Nov 17:15" },
];

const CustomStepper = () => {
  return (
    <div style={{ width: "100%", margin: "20px 0" }}>
      <Stepper alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>
              <div style={{ textAlign: "center" }}>
                <Typography variant="body2" gutterBottom>
                  {step.label}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {step.timestamp}
                </Typography>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomStepper;
