import { useQuery } from "react-query";
import DashboardApi from "../apis/dashboard";

const useDashboardData = (token, dateFilter) => {
  let apiError = null;
  const {
    data: totalCandidates,
    isLoading,
    error: errorTotalCandidates,
  } = useQuery({
    queryKey: ["totalCandidates", dateFilter.from, dateFilter.to],
    queryFn: () =>
      DashboardApi.getTotalCandidates({
        startTime: dateFilter.from,
        endTime: dateFilter.to,
        token,
      }),
    staleTime: 30 * 1000,
    enabled: !!dateFilter.from && !!dateFilter.to,
  });

  const { data: meanScore, error: errorMeanScore } = useQuery({
    queryKey: ["meanScore"],
    queryFn: () =>
      DashboardApi.getMeanScore({
        token,
        startTime: dateFilter.from,
        endTime: dateFilter.to,
      }),
  });

  const { data: assessmentTime, error: errorAssessmentTime } = useQuery({
    queryKey: ["assessmentTime", dateFilter.from, dateFilter.to],
    queryFn: () =>
      DashboardApi.getAssessmentTime({
        token,
        startTime: dateFilter.from,
        endTime: dateFilter.to,
      }),
  });

  const { data: savedTime, error: errorSavedTime } = useQuery({
    queryKey: ["savedTime", dateFilter.from, dateFilter.to],
    queryFn: () =>
      DashboardApi.getSaved({
        token,
        startTime: dateFilter.from,
        endTime: dateFilter.to,
      }),
    refetchOnWindowFocus: false,
  });

  apiError =
    errorTotalCandidates ||
    errorMeanScore ||
    errorAssessmentTime ||
    errorSavedTime;

  return {
    totalCandidates,
    meanScore,
    assessmentTime,
    savedTime,
    isLoading,
    error: apiError,
  };
};

export default useDashboardData;
