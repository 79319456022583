import React from "react";
import { useQuery } from "react-query";
import Loading from "../../common/Loading";
import ErrorPage from "../../common/ErrorPage";
import NoData from "../../common/NoData";
import ReportCardApi from "../../apis/ReportCardApi";
import useStore from "../../store/useStore";
import { useParams } from "react-router-dom";
import StudentInfo from "./StudentInfo";
import ScoreDisplay from "./ScoreDisplay";
import BenchmarkBar from "./BenchMarks";
import SkillwiseScores from "./SkillWise";
import SectionwiseScores from "./SectionwiseScroes";
import CodeReviewDashboard from "./CodeReviewDashboard";
import BlockMobileAccess from "./MobileAccess";
import { useState, useEffect } from "react";
const ReportCard = () => {
  // Fetch report card data using react-query
  const [isMobile, setIsMobile] = useState(false);
  


const token=useStore((state)=>state.token);

const {examId,userId}=useParams();
  const fetchReportCardData=async()=>{
    const response=await ReportCardApi.show({token,userId,examId});
    return response;
  }
  const { data, isLoading, isError } = useQuery({
    queryKey: ["reportCard"],
    queryFn: fetchReportCardData,
  });

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Consider 768px or adjust as necessary for your design
    };

    checkMobile(); // Run on component mount
    window.addEventListener("resize", checkMobile); // Listen for window resize events

    return () => window.removeEventListener("resize", checkMobile);
  }, []);
  if (!token || !examId || !userId) {
    return <div>Invalid request</div>;
  }
  if (isLoading) return <Loading />;
  if (isError) return <ErrorPage />;
  if (!data) return <NoData />;

  return (
    <>
      {isMobile ? (
        <BlockMobileAccess />
      ) : (
        <div className="flex flex-col justify-center items-center min-h-screen">
          <div className="flex flex-col justify-center px-8 py-2 lg:mx-auto max-w-7xl w-full">
            <StudentInfo
              name={data?.username || "user"} // You might want to fetch the actual name from somewhere
              role={data?.examName || "role"} // This should come from the API or be determined based on the score
              logoUrl="https://cdn.prod.website-files.com/664dbaeb27d0659c9685b3a7/66c61305181d384dea3e8372_Logosimple%20small%20txt.svg" // This should come from the API
              examUserId={data?.examUserId || 0}
              token={token || "N/A"}
            />
            <div className="flex flex-col sm:flex-row gap-10 mt-4  w-full">
              <ScoreDisplay
                score={data?.KodivaScore ? Math.round(data.KodivaScore) : 0}
                label="Kodiva Score"
              />
              <BenchmarkBar
                benchmarkPoints={
                  data?.benchmarking
                    ? [
                        data.benchmarking?.SDE1 || data.benchmarking?.sde1 || 0,
                        data.benchmarking?.Mean || data.benchmarking?.mean || 0,
                        data.benchmarking?.SDE2 || data.benchmarking?.sde2 || 0,
                      ]
                    : [0, 0, 0]
                }
                value={data?.KodivaScore ? Math.round(data.KodivaScore) : 0}
              />
            </div>
            <SkillwiseScores
              skills={
                data?.skillScores
                  ? data.skillScores.map((skill) => ({
                      title: skill.skill,
                      value: skill.actualScore,
                      markers: [skill.rejected, skill.passed],
                    }))
                  : []
              }
            />
            <SectionwiseScores
              sections={
                data?.sectionScores
                  ? Object.entries(data.sectionScores).map(
                      ([sectionName, sectionData]) => ({
                        title: sectionName || "",
                        score: Math.round(sectionData?.score) || 0,
                        items: sectionData?.capabilities
                          ? Object.entries(sectionData.capabilities).map(
                              ([capabilityName, capabilityScore]) => ({
                                label: capabilityName || "",
                                score: Math.round(capabilityScore) || 0,
                                status:
                                  capabilityScore >= 80
                                    ? "success"
                                    : capabilityScore > 60
                                    ? "warning"
                                    : "danger",
                              })
                            )
                          : [],
                      })
                    )
                  : []
              }
            />

            <CodeReviewDashboard
              codeReview={{
                subScores: data?.questions
                  ? data.questions.map((section) => ({
                      title: section?.questionTitle || "",
                      score: Math.round(section?.marksScored) || 0,
                      subtitle: section?.sectionName || "",
                      scores: Object.keys(section?.subdivisionMarks || {}).map(
                        (category) => ({
                          label: category || "",
                          score: Math.round(
                            Object.values(
                              section?.subdivisionMarks[category] || {}
                            )[0] || 0
                          ),
                          subCategory: Object.entries(
                            section?.subdivisionMarks[category] || {}
                          ).map(([subcategoryName, subScore]) => ({
                            label: subcategoryName || "",
                            score: Math.round(subScore) || 0,
                          })),
                        })
                      ),
                    }))
                  : [],
                pullRequest: data?.questions?.[0]?.url || "No URL available",
                feedback: {
                  liked:
                    data?.questions?.[0]?.overallFeedback?.whatWeLike ||
                    "No feedback",
                  disliked:
                    data?.questions?.[0]?.overallFeedback?.whatWeDislike ||
                    "No feedback",
                  expected:
                    data?.questions?.[0]?.overallFeedback?.whatWeExpect ||
                    "No expectations",
                  lacking:
                    data?.questions?.[0]?.overallFeedback?.whatWasLacking ||
                    "No lacking points",
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReportCard;
