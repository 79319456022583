import React, { useState } from "react";
import { Button } from "../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Calendar } from "../ui/calendar";
import { cn, formatUTCDate } from "../../lib/utils";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";

// Convert a date to UTC string (ISO format)

// Disable future dates by comparing with the current date
const disableFutureDates = (date) => {
  const currentDate = new Date();
  return new Date(date).getTime() > currentDate.getTime();
};

const DatePicker = ({ dateFilter, setDateFilter, handleDateBlur }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Handle date range selection
  const handleDateSelect = (range) => {
    if (range?.from) {
      setDateFilter({
        from: range.from, // Store as local date
        to: range.to ? range.to : range.from,
      });
    }
  };

  // Handle popover visibility
  const handlePopoverChange = (open) => {
    setIsOpen(open);
  };

  // Handle the submission of the selected date range
  const handleSubmitDate = () => {
    setIsOpen(false);
    handleDateBlur();
  };

  return (
    <div className="grid gap-2 grid-cols-2">
      <Popover onOpenChange={handlePopoverChange} open={isOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal",
              !dateFilter?.from && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateFilter?.from ? (
              dateFilter.to ? (
                <>
                  {format(dateFilter.from, "LLL dd, y")} -{" "}
                  {format(dateFilter.to, "LLL dd, y")}
                </>
              ) : (
                dateFilter.from
              )
            ) : (
              format(dateFilter.from, "LLL dd, y")
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 " align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={new Date(dateFilter?.from || new Date())}
            selected={dateFilter}
            onSelect={handleDateSelect}
            numberOfMonths={2}
            disabled={(date) => disableFutureDates(date)}
          />
          <div className="flex justify-end mx-2 mb-2">
            <Button className="" onClick={handleSubmitDate}>
              Save
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const Filter = ({ dateFilter, setDateFilter, handleDateBlur }) => {
  return (
    <div className="flex flex-wrap gap-4">
      <div className="flex gap-4">
        <DatePicker
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          handleDateBlur={handleDateBlur}
        />
      </div>
    </div>
  );
};

export default Filter;
