import { Button } from "../ui/button"
import { DataTableDemo } from "../ui/DataTable"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import {
    Sheet,
    SheetPortal,
    SheetOverlay,
    SheetTrigger,
    SheetClose,
    SheetContent,
    SheetHeader,
    SheetFooter,
    SheetTitle,
    SheetDescription,
  } from  "../ui/sheet"


export function ReportCardGenerate({ shortlistedArray}) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline">Share ReportCard</Button>
      </SheetTrigger>
      <SheetContent>
       <DataTableDemo  shortlistedArray={ shortlistedArray}/>
      </SheetContent>
    </Sheet>
  )
}
