const BenchmarkBar = ({
    benchmarkPoints,
    value,
  }) => {
    const [sde1, mean, sde2] = benchmarkPoints;
    const dotPosition = `${value}%`;
    const meanPosition = `${mean}%`;
    const sde1Position = `${sde1}%`;
    const sde2Position = `${sde2}%`;
    const max = 100;
    const marks = [sde1, mean, max];
    let preMarker = 0;
    const getColorClass = (index) => {
      if (index === 1) return "bg-orangeColor";
      if (index === 2) return "bg-forestGreen";
      return "bg-redColor";
    };
  
    return (
      <div className="px-4 py-4 bg-white rounded-lg shadow border-[0.5px] border-grayDisabled w-full ">
        <div className="text-[17px]  mb-2">Benchmarking</div>
        <div className="relative h-5 rounded-full ">
          <div
            className="absolute  text-md  -ml-3 "
            style={{ left: meanPosition }}
          >
            <span>Mean</span>
          </div>
          <div
            className="absolute  text-md  -ml-3 "
            style={{ left: sde1Position }}
          >
            <span>SDE1</span>
          </div>
          <div
            className="absolute  text-md  -ml-3 "
            style={{ left: sde2Position }}
          >
            <span>SDE2</span>
          </div>
        </div>
        <div className="relative h-1 rounded-full  mt-2">
          {marks.map((mark, index) => {
            const width =
              index === marks.length - 1 ? 100 - preMarker : mark - preMarker;
            const divElement = (
              <div
                key={index}
                className={`absolute h-full ${getColorClass(index)}`}
                style={{ width: `${width}%`, left: `${preMarker}%` }}
              ></div>
            );
            preMarker = mark;
            return divElement;
          })}
          <div
            className="absolute  flex justify-center items-center"
            style={{ left: dotPosition, top: -2 }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cy="4" cx="4" r="4" fill="#191C45" />
            </svg>
          </div>
        </div>
        <div className="relative my-2 mb-8">
          <div
            className="absolute  text-md -ml-1.5"
            style={{ left: meanPosition }}
          >
            {value !== mean && <span>{mean}</span>}
          </div>
          <div
            className="absolute  text-md -ml-1.5"
            style={{ left: sde1Position }}
          >
            {value !== sde1 && <span>{sde1}</span>}
          </div>
          <div
            className="absolute  text-md -ml-1.5"
            style={{ left: sde2Position }}
          >
            {value !== sde2 && <span>{sde2}</span>}
          </div>
          <div
            className="absolute rounded-full  text-md py-0.5 px-1 -ml-3 text-black font-bold"
            style={{ left: dotPosition }}
          >
            <span>{value}</span>
          </div>
        </div>
      </div>
    );
  };
  export default BenchmarkBar;