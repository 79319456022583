import { ChevronDown } from "lucide-react";
import { CardContent } from "../../ui/card";
import { Card } from "../../ui/card";
import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import InfoIcon from "../../../assets/icons/Info";
import BriefCaseIcon from "../../../assets/icons/BriefCaseIcon";
import LocationIcon from "../../../assets/icons/LocationIcon";
import ClockIcon from "../../../assets/icons/ClockIcon";
import CandidateInfo from "../CandidateInfo";
import { Sheet, SheetContent, SheetTrigger } from "../../ui/sheet";

const CandidateListCard = ({ student }) => {
  return (
    <Card className="shadow-lg">
      <CardContent className="p-4 pt-0">
        <div className="flex items-center justify-between gap-10 py-4 border-b border-[#CACACA]">
          <div className="inline-flex gap-4 w-full">
            <div className=" divide-x divide-[#C3C3C3] flex capitalize text-base ">
              <Sheet>
                <SheetTrigger asChild>
                  <p className="pr-4">{student.name}</p>
                </SheetTrigger>
                <SheetContent className="sm:max-w-lg">
                  <CandidateInfo student={student} />
                </SheetContent>
              </Sheet>

              <p className="flex gap-1 pl-4 items-center">
                <BriefCaseIcon /> <span>{student.role}</span>
              </p>
            </div>
            <p>{student.yearOfExperience}</p>
          </div>
          <div className="inline-flex gap-4 text-sm text-[#191C45]">
            <button className="text-nowrap border border-[#191C45] rounded-full px-2 py-1 ">
              View Resume
            </button>
            <button className="flex items-center gap-2 border border-[#191C45] rounded-full px-2 py-1">
              <span>Actions</span> <ChevronDown size={16} />
            </button>
          </div>
        </div>
        <div className="flex items-center gap-4 justify-between text-sm pt-4 text-[#181818]">
          <div className="flex capitalize gap-4 ">
            <p className="">{student.name}</p>
            <p className="flex gap-1 items-center">
              <ClockIcon /> <span>{student.yearOfExperience}</span>
            </p>
            <p className="flex gap-1 items-center">
              <LocationIcon /> <span>{student.location}</span>
            </p>
          </div>
          <div className="flex gap-2 ">
            <p className="text-[#383838]">Role Fit Score</p>
            <span className="text-[#3C59B3] font-semibold">
              {student.score}%
            </span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Add to library</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CandidateListCard;
