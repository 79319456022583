import axios  from "axios";

const fetch=async({examUserId,token,examId})=>{
 const url=`${process.env.REACT_APP_PUBLIC_URL}/exam/${examId}/reset`;
 try {
    const response= await axios.post(url,{
        examUserId:examUserId,
        sendMail:true,
    },{
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
 
    return response.data;
 } catch (error) {
    console.log(error);
 }

};
const ResetApis={fetch};
export default ResetApis;