import { cn } from "../../lib/utils";
import React from "react";

const Tag = ({ title,className }) => {
  return (
    <div className={cn("w-fit rounded-2xl px-3 py-2 text-nowrap bg-[#EAFFDD] text-black text-xs", className)}>
      {title}
    </div>
  );
};

export default Tag;
