import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = () => {
    return (
        <div className="flex flex-col justify-center items-center min-h-screen">
            <Box sx={{ display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',  }}>
                <CircularProgress />
            </Box>
        </div>
    );
};

export default Loading;
